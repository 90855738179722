@import "../../assets/scss/variable";

// FULL SECTION
.full-section{
  min-height: 100vh;
  background-color: $lightGreen;
  width: 100%;
  padding: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  .logo-img{
    width: 140px;
    position: absolute;
    top: 40px;
    left: 40px;
    @media (max-width: map-get($mediaSizes, "md")) {
      width: 120px;
      top: 30px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
  .licence-box{
    max-width: 700px;
    margin: 0 auto;
  }
  h1{
    color: $white;
    font-size: 60px;
    line-height: 70px;
    font-weight: 700;
    margin-bottom: 20px;
    text-transform: uppercase;
    @media (max-width: map-get($mediaSizes, "lg")) {
      font-size: 50px;
      line-height: 60px;
    }
    @media (max-width: map-get($mediaSizes, "md")) {
      font-size: 40px;
      line-height: 50px;
    }
  }
  p{
    margin: 30px 0 ;
    @include font2037();
    color: $white;
    font-weight: 600;
    @media (max-width: map-get($mediaSizes, "lg")) {
      @include font1620();
    }
  }
  button{
    min-height: 50px;
    height: 50px;
    border-radius: 30px;
    padding: 5px 30px !important;
    svg{
      margin-top: -2px;
    }
  }
}

// RTL CSS
[dir="rtl"] {
  .full-section{

  }
}
