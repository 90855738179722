@import "../../assets/scss/variable";

// SERVICE SECTION

.sticky-btn {
  text-align: center;
  position: sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  bottom: 10px;
  margin-top: 25px;
  background-color: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding-block: 20px;
  border-radius: 16px;
  z-index: 1000;

  // @media (max-width: map-get($mediaSizes, "sm")) {
  //   position: fixed;
  //   left: 0;
  //   right: 0;
  //   margin: 0 auto;
  //   z-index: 999;
  // }
}

.service-section {
  padding: 60px 0 40px;


  @media (max-width: map-get($mediaSizes, "lg")) {
    padding: 30px 0 20px;
  }


  .service-section-data {
    width: 100%;
    display: flex;
    align-items: stretch;

    @media (max-width: map-get($mediaSizes, "lg")) {
      display: block;
    }


    .service-details-left-data {
      width: calc(100% - 354px);
      padding-right: 40px;


      @media (max-width: map-get($mediaSizes, "xl")) {
        width: calc(100% - 300px);
        padding-right: 20px;
      }


      @media (max-width: map-get($mediaSizes, "lg")) {
        width: 100%;
        padding-right: 0;
      }

      .service-details-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px;

        .head-data {
          width: calc(100% - 100px);
          margin-bottom: 0;
        }

        .replace-data {
          text-align: right;
          width: 100px;

          span.btn-light,
          a.btn-light {
            height: 20px;
            border-radius: 15px;
            background-color: $offWhite;
            color: $darkGreen;
            @include font1220();
            padding: 0px 10px;
            transition: 0.3s ease-in-out;
            text-decoration: none;
            cursor: pointer;
            text-transform: capitalize;

            &:hover {
              background-color: $lightGreen;
              color: $white;

              svg {
                color: $white;
              }
            }

            svg {
              transition: 0.3s ease-in-out;
            }
          }
        }
      }
    }

    .choose-provider-data {
      &.profile-card {
        border: 1px solid rgba($black, 0.1);
        box-shadow: 0px 5px 10px rgba($black, 0.1);
        overflow: unset;
      }

      &.grey-border {
        border: 1px solid rgba($black, 0.1);
        box-shadow: 0px 5px 10px rgba($black, 0.1);
      }

      border: 1px solid $primary;
      border-radius: 20px;
      overflow: hidden;

      &.overflow-unset {
        overflow: unset !important;
      }

      .scroll-data {
        max-height: 650px;
        overflow-y: auto;
        @include scroll_vertical_main_hover();
        scrollbar-gutter: stable;
        padding: 20px 15px;

        &.scroll-none {
          padding: 0;
          overflow-y: unset;
          max-height: unset;
        }
      }

      ul.choose-ul {
        padding: 0;
        margin: 0;

        li.choose-li {
          margin-bottom: 20px;
          display: block;

          &:last-child {
            margin-bottom: 0px;
          }

          .inner-provider-card {
            padding: 20px;
            border-radius: 20px;
            box-shadow: 0px 5px 10px $gray;
            display: flex;
            align-items: center;
            width: 100%;
            cursor: pointer;


            @media all and (max-width: 650px) {
              display: block;
            }

            .one {
              width: 80px;
              height: 80px;
              border-radius: 50%;
              background-color: $cuColorc8;
              // border: 1px solid $cuColorc8 !important;
              @include bgCover();

              &.golden-border {
                border: 2px solid $warning !important;
              }

              @media all and (max-width: 650px) {
                display: block;
                margin: 0 auto;
              }
            }

            .two {
              &.profile-card-two {
                width: calc(100% - 190px);
                margin: 0 auto;
              }

              width: calc(100% - 110px);
              padding: 0 15px;

              @media all and (max-width: 650px) {
                display: block;
                width: 100% !important;
                padding: 20px 0;
              }

              ul {
                padding: 0;
                margin: 0;

                @media all and (max-width: 650px) {
                  margin: 0 auto;
                  width: fit-content;
                }

                li {
                  display: block;
                  padding: 2.5px 0;

                  &:first-child {
                    padding-top: 0;
                  }

                  &:last-child {
                    padding-bottom: 0;
                  }

                  .main-label {
                    @include font1426();
                    font-weight: 600;
                    color: $darkGreen;

                    @media (max-width: map-get($mediaSizes, "xl")) {
                      @include font1420();
                    }

                    @media all and (max-width: 500px) {
                      text-align: center;
                    }
                  }

                  .name-data {
                    @include font1630();
                    font-weight: 700;
                    color: $darkGreen;
                    display: flex;
                    align-items: center;
                    text-transform: capitalize;

                    @media (max-width: map-get($mediaSizes, "xl")) {
                      @include font1420();
                    }

                    @media all and (max-width: 650px) {
                      justify-content: center;
                    }

                    svg {
                      margin-left: 5px;
                    }
                  }

                  .star-data {
                    width: 100%;
                    display: flex;
                    align-items: center;

                    .user-select-none {
                      pointer-events: none;
                      user-select: none;
                      display: flex;
                      align-items: center;
                      margin-right: 2.5px;

                      span {
                        margin-right: 2.5px;
                      }
                    }

                    .comment-data {
                      color: $cuColor87;
                      font-weight: 700;
                      @include font1220();
                      padding-top: 3px;
                    }
                  }

                  .icon-data {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    @media (max-width: map-get($mediaSizes, "xl")) {
                      gap: 8px;
                    }

                    .icon-i {
                      width: 20px;

                      @media (max-width: map-get($mediaSizes, "xl")) {
                        width: 16px;
                      }

                      svg {
                        max-width: 100%;
                      }
                    }

                    .text-i {
                      width: calc(100% - 20px);
                      @include font1630();
                      font-weight: 700;
                      color: $darkGreen;
                      @include linebreak1();

                      @media (max-width: map-get($mediaSizes, "xl")) {
                        @include font1420();
                        width: 100%;
                      }

                      &.ispan {
                        font-weight: 600;
                        @include font1626();

                        @media (max-width: map-get($mediaSizes, "xl")) {
                          @include font1420();
                        }
                      }
                    }
                  }
                }
              }

              .small-details-data {
                .span-two {
                  grid-column: span 2;
                }

                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 10px;

                @media all and (max-width: 650px) {
                  grid-template-columns: repeat(1, 1fr);
                  gap: 15px;
                }
              }
            }

            .three {
              &.show-profile {

                width: unset;
                height: unset;
                background-color: unset;

                span {
                  white-space: nowrap;
                  font-size: 14px;
                  padding-inline: 12px;
                  border-radius: 10px;
                  background-color: $offWhite;

                  svg {
                    width: 18px;
                    height: 18px;
                  }
                }

                @media all and (max-width: 650px) {
                  margin: 0 auto;
                  transform: unset;
                }
              }

              width: 30px;
              height: 30px;
              border-radius: 50%;
              @include all_center();
              background-color: $offWhite;
              color: $darkGreen;
              cursor: pointer;

              @media all and (max-width: 650px) {
                margin: 0 auto;
                transform: rotate(90deg);
              }
            }
          }
        }
      }
    }

    .brief-card {
      &.white-bg-card {
        background-color: $white;
        box-shadow: 0px 5px 10px rgba($black, 0.1);
        border: 1px solid rgba($black, 0.1);

      }

      padding: 20px;
      border-radius: 20px;
      background-color: $offWhite;
      margin: 40px 0;
      position: relative;

      .heading {
        @include font2037();
        font-weight: 600;
        color: $darkGreen;
        margin-bottom: 20px;

        @media (max-width: map-get($mediaSizes, "lg")) {
          @include font1626();
        }
      }

      .inner-brief-border-card {
        border-bottom: 1px solid $lightGreen;
        margin-bottom: 20px;
        padding-bottom: 10px;

        &:last-child {
          border-bottom: 0;
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }

      .btn-box {
        display: flex;
        align-items: center;
        gap: 20px;
      }

      .listing-attachments {
        padding-left: 0;
        margin-bottom: 20px;
        max-height: 250px;
        overflow-y: auto;
        width: calc(100% + 10px);
        padding-right: 10px;
        @include scroll_vertical_primary();

        li {
          margin-bottom: 8px;
          display: block;

          .list-data {
            width: 100%;
            display: flex;
            align-items: center;
            background-color: $white;
            padding: 5px 10px;
            border-radius: 20px;
            column-gap: 20px;

            .one {
              min-width: 30px;
              min-height: 30px;
              border-radius: 50%;
              background-color: $danger;
              color: $white;
              cursor: pointer;
              @include all_center();

              svg {
                @include trans3();
              }

              &:hover {
                svg {
                  transform: rotate(-90deg);
                }
              }
            }

            .two {
              width: calc(100% - 30px);

              p {
                margin-bottom: 0;
                color: $darkGreen;
                @include font1420();
                font-weight: 600;
                word-break: break-all;
              }
            }
          }
        }
      }

      .attach-btns.with-white {
        border: 1px solid $white;
        background-color: $white;
        color: $darkGreen;
        height: 40px;
        border-radius: 20px;
        padding: 5px 17px 5px 11px;
        @include all_center();
        width: fit-content;
        min-width: 96px;
        cursor: pointer;
        @include trans3();
        @include font1420();
        font-weight: 600;

        &:hover {
          border: 1px solid $lightGreen;
        }

        svg {
          margin-right: 10px;
          width: 18px;
          height: 20px;
        }
      }
    }

    .promo-box {
      margin-bottom: 20px;

      p {
        @include font2037();
        font-weight: 600;
        color: $darkGreen;
        padding-top: 2.5px;

        @media (max-width: map-get($mediaSizes, "lg")) {
          @include font1626();
        }

        @media (max-width: map-get($mediaSizes, "md")) {
          margin-bottom: 20px;
        }
      }
    }

    .service-left-data {
      width: calc(100% - 414px);
      padding-right: 40px;
      height: calc(100vh - 200px);

      @media (max-width: map-get($mediaSizes, "xl")) {
        width: calc(100% - 300px);
        padding-right: 20px;
      }

      @media (max-width: map-get($mediaSizes, "lg")) {
        width: 100%;
        padding-right: 0;
        max-height: 400px;
        height: 400px;
      }

      @media (max-width: map-get($mediaSizes, "md")) {
        max-height: 700px;
        height: 700px;
      }

      .inner-service-data {
        border: 1px solid $primary;
        border-radius: 20px;
        height: calc(100% - 47px);
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        @media (max-width: map-get($mediaSizes, "md")) {
          grid-template-columns: repeat(1, 1fr);
        }

        &.full {
          grid-template-columns: repeat(1, 1fr);
        }

        overflow: hidden;

        .service-listing {
          padding: 10px;
          overflow-y: auto;
          @include scroll_vertical_main_hover();

          ul {
            padding: 0;
            margin: 0;

            li {
              display: block;
              padding: 6px 0 10px;
              border-bottom: 1px solid $primary;

              &:last-child {
                border-bottom: 0;
              }
            }
          }
        }

        .service-inner-listing {
          border-left: 1px solid $primary;
          padding: 10px;
          overflow-y: auto;
          @include scroll_vertical_main_hover();

          @media (max-width: map-get($mediaSizes, "md")) {
            border-left: 0;
            border-top: 1px solid $primary;
          }

          .no-data-section {
            height: 100%;
          }

          ul {
            padding: 0;
            margin: 0;

            li {
              display: block;
              padding: 15px 0 16px;
              border-bottom: 1px solid $cuColorc8;

              .service-inner-list-card {
                padding: 5px 10px;
                border-radius: 15px;
                @include trans3();
                width: 100%;
                display: flex;
                align-items: center;
                background: $white;
                cursor: pointer;
                text-decoration: none;

                &:hover {
                  background: $primary;

                  .two {

                    span,
                    a {
                      color: $white;
                    }
                  }
                }

                .one {
                  width: 35px;
                  height: 35px;
                  border-radius: 8px;
                  border: 2px solid $white;
                  background: $cuColorc8;
                  background-size: 100% !important;
                }

                .two {
                  width: calc(100% - 35px);
                  padding: 0 0 0 10px;

                  span,
                  a {
                    display: block;
                    @include font1426();
                    color: $darkGreen;
                    font-weight: 600;
                    text-transform: capitalize;
                    @include trans3();
                    @include linebreak2();
                    text-decoration: unset;
                    word-break: break-word;
                  }
                }
              }
            }
          }
        }
      }
    }

    .order-summary-shimmer {
      width: 354px;
      position: sticky;
      top: 20px;
      border-radius: 20px;
      padding: 20px;
      height: calc(100vh - 200px);
      display: flex;
      flex-direction: column;

      @media (max-width: map-get($mediaSizes, "xl")) {
        width: 300px;
        padding: 20px 15px;
      }

      @media (max-width: map-get($mediaSizes, "lg")) {
        width: 100%;
        margin-top: 30px;
        height: 400px;
      }
    }

    .order-summary-data {
      width: 414px;
      position: sticky;
      top: 20px;
      overflow: hidden;
      background: $offWhite;
      border-radius: 20px;
      padding: 20px;
      // height: calc(100vh - 200px);
      display: flex;
      flex-direction: column;

      @media (max-width: map-get($mediaSizes, "xl")) {
        width: 300px;
        padding: 20px 15px;
      }

      @media (max-width: map-get($mediaSizes, "lg")) {
        width: 100%;
        margin-top: 30px;
        // max-height: 400px;
        height: unset;
      }

      .head-data {
        flex: initial;
      }

      .scroll-data {
        flex: 1%;
        height: 100%;
        overflow-y: auto;
        width: calc(100% + 10px);
        padding-right: 10px;
        @include scroll_vertical_white_hover();

        .order-box {
          &.mb20 {
            margin-bottom: 20px;
          }

          .outer-ul {
            padding-left: 0;
            margin-bottom: 0;

            li {
              display: block;
              position: relative;

              p {
                margin-bottom: 0;
              }

              &.inner-li {
                padding-left: 20px;

                &::before {
                  content: "";
                  position: absolute;
                  top: 13px;
                  left: 5px;
                  height: 5px;
                  width: 5px;
                  border-radius: 50%;
                  background-color: $darkGreen;
                }
              }
            }
          }

          p {
            margin-bottom: 0;

            &.head-text {
              @include font1426();
              color: $lightGreen;
              font-weight: 600;
              margin-bottom: 5px;
              text-transform: capitalize;

              &.font-lower {
                text-transform: none
              }
            }

            &.list-text {
              @include font1630();
              color: $cuColor87;
              font-weight: 700;
              margin-bottom: 5px;
              text-transform: capitalize;
              word-break: break-all;
            }
          }
        }

        .order-provider-box {
          padding: 10px;
          border-radius: 20px;
          background-color: $white;

          ul {
            padding: 0;
            margin: 0;

            li {
              display: block;

              .name-data {
                @include font1630();
                font-weight: 700;
                color: $cuColor87;
                display: flex;
                align-items: center;
                text-transform: capitalize;

                @media (max-width: map-get($mediaSizes, "xl")) {
                  @include font1420();
                }

                svg {
                  margin-left: 5px;
                }
              }

              .star-data {
                width: 100%;
                display: flex;
                align-items: center;

                .user-select-none {
                  pointer-events: none;
                  user-select: none;
                  display: flex;
                  align-items: center;
                  margin-right: 2.5px;

                  span {
                    margin-right: 2.5px;
                  }
                }

                .comment-data {
                  color: $cuColor87;
                  font-weight: 700;
                  @include font1220();
                  padding-top: 3px;
                }
              }

              .icon-data {
                display: flex;
                align-items: center;
                gap: 10px;

                @media (max-width: map-get($mediaSizes, "xl")) {
                  gap: 8px;
                }

                .icon-i {
                  width: 20px;

                  @media (max-width: map-get($mediaSizes, "xl")) {
                    width: 16px;
                  }

                  svg {
                    max-width: 100%;
                  }
                }

                .text-i {
                  width: calc(100% - 20px);
                  @include font1630();
                  font-weight: 700;
                  color: $darkGreen;

                  @media (max-width: map-get($mediaSizes, "xl")) {
                    @include font1420();
                    width: calc(100% - 16px);
                  }
                }
              }
            }
          }
        }
      }
    }

    .service-main-card {
      padding: 10px;
      border-radius: 20px;
      @include trans3();
      width: 100%;
      display: flex;
      align-items: center;
      background: $white;
      cursor: pointer;

      &:hover,
      &.active {
        background: $primary;

        .two {

          span,
          a {
            color: $white;
          }
        }
      }

      &.full {
        border: 1px solid $darkGreen;

        &:hover,
        &.active {
          border: 1px solid $lightGreen;
        }

        padding: 20px;

        .two {
          width: calc(100% - 60px);
          padding-right: 0;
        }
      }

      .one {
        width: 60px;
        height: 60px;
        border-radius: 10px;
        border: 2px solid $white;
        background: $cuColorc8;
        background-size: 100% !important;
      }

      .two {
        width: calc(100% - 90px);
        padding: 0 15px;

        span,
        a {
          display: block;
          @include font1426();
          color: $darkGreen;
          font-weight: 600;
          text-transform: capitalize;
          @include trans3();
          @include linebreak2();
          word-break: break-word;
          text-decoration: unset;
        }
      }

      .three {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        @include all_center();
        background-color: $offWhite;
        color: $darkGreen;
      }
    }

    .head-data {
      margin-bottom: 10px;

      &.mb20 {
        margin-bottom: 20px;
      }

      .back-arrow {
        margin-right: 10px;
      }

      &.with-big {
        .back-arrow {
          height: fit-content;
          margin-top: 7px;

          @media (max-width: map-get($mediaSizes, "lg")) {
            margin-top: 1px;
          }
        }
      }

      h3 {
        @include font2037();
        font-weight: 600;
        color: $darkGreen;
        margin-bottom: 0px;

        @media (max-width: map-get($mediaSizes, "lg")) {
          @include font1626();
        }

        span.ttc {
          text-transform: capitalize;
        }
      }

      .icon-text {
        @include font1420();
        font-weight: 700;
        color: $cuColor87;
      }
    }
  }
}

.provider-modal {
  &.video-modal {
    .modal-body {
      overflow: unset;
    }

    .video-preview {
      display: flex;
      border-radius: 20px;
      overflow: hidden;
    }
  }

  &.modal-dialog {
    width: calc(100% - 30px);
    margin-left: auto;
    margin-right: auto;

    @media (max-width: map-get($mediaSizes, "mid")) {
      max-width: 880px;
    }

    @media (min-width: map-get($mediaSizes, "sm")) {
      max-width: 880px;
    }
  }

  .modal-content {
    border: 0;
    border-radius: 20px !important;
    box-shadow: 0px 5px 10px rgba($black, 0.1);
  }

  .modal-body {
    padding: 40px;
    overflow: hidden;

    &.over-unset {
      overflow: unset;
    }

    .modal-body-scroll {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 85vh;
      width: calc(100% + 30px);
      padding-right: 30px;
      @include scroll_vertical_main_hover();
    }

    .profile-box {
      max-width: 400px;
      margin: 0 auto 60px;

      .profile-img {
        width: 180px;
        height: 180px;
        border-radius: 50%;
        margin: 0 auto 15px auto;
        background-color: $cuColorc8;
        border: 1px solid $cuColorc8 !important;
        @include bgCover();

        &.golden-border {
          border: 3px solid $warning !important;
        }
      }

      span.designation {
        display: block;
        @include font1220();
        color: $darkGreen;
        font-weight: 600;
        margin-bottom: 30px;
        text-align: center;
      }

      .name-data {
        display: flex;
        align-items: center;
        justify-content: center;
        @include font1630();
        font-weight: 700;
        color: $darkGreen;
        margin-bottom: 12px;
        text-transform: capitalize;

        svg {
          margin-left: 5px;
        }
      }

      .rate-data {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;

        @media (max-width: map-get($mediaSizes, "sm")) {
          display: block;
          margin-bottom: 5px;
        }

        .one {
          display: flex;
          align-items: center;

          @media (max-width: map-get($mediaSizes, "sm")) {
            justify-content: center;
            margin-bottom: 5px;
          }
        }

        .comment-data {
          padding-right: 13px;
          color: $cuColor87;
          font-weight: 700;
          @include font1220();
          padding-top: 3px;
        }

        .user-select-none {
          pointer-events: none;
          user-select: none;
          margin-right: 2.5px;

          span {
            margin-right: 2.5px;
          }
        }

        .icon-i {
          svg {
            margin-top: -5px;
          }
        }

        .text-i.ispan {
          color: $darkGreen;
          font-weight: 600;
          @include font1626();
          padding-left: 10px;
        }
      }
    }

    .off-card {
      padding: 20px;
      border-radius: 20px;
      background-color: $offWhite;
      margin-bottom: 40px;
      height: calc(100% - 40px);

      .heading {
        @include font2037();
        font-weight: 600;
        color: $darkGreen;
        margin-bottom: 20px;
        text-transform: capitalize;

        @media (max-width: map-get($mediaSizes, "lg")) {
          @include font1626();
        }
      }
    }

    .expertise-data {
      ul {
        padding: 0;
        margin: 0;

        li {
          display: block;
          padding: 10px 0;
          border-bottom: 1px solid $cuColorc8;

          &:first-child {
            padding-top: 0;
          }

          &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
          }

          .exp-box {
            display: flex;
            align-items: flex-start;

            .one {
              width: 20px;
            }

            .two {
              padding-left: 15px;
              width: calc(100% - 20px);

              p {
                @include font1426();
                color: $darkGreen;
                font-weight: 600;
                margin-bottom: 0;
                text-transform: capitalize;
              }

              span {
                @include font1220();
                color: $cuColor87;
                font-weight: 400;
              }
            }
          }
        }
      }
    }

    .education-data {
      ul {
        padding: 0;
        margin: 0;

        li {
          display: block;
          padding: 10px 0;
          border-bottom: 1px solid $cuColorc8;

          &:first-child {
            padding-top: 0;
          }

          &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
          }

          .exp-box {
            display: flex;
            align-items: flex-start;

            .one {
              width: 20px;
              color: $lightGreen;
            }

            .two {
              padding-left: 15px;
              width: calc(100% - 20px);

              p {
                @include font1426();
                color: $darkGreen;
                font-weight: 600;
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }

    .contact-data {
      ul {
        padding: 0;
        margin: 0;

        li {
          display: block;
          padding: 10px 0;
          border-bottom: 1px solid $cuColorc8;

          &:first-child {
            padding-top: 0;
          }

          &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
          }

          .exp-box {
            display: flex;
            align-items: flex-start;

            .one {
              width: 20px;
              color: red;
            }

            .two {
              padding-left: 15px;
              width: calc(100% - 20px);

              p,
              a {
                @include font1426();
                color: $darkGreen;
                font-weight: 600;
                margin-bottom: 0;
                display: block;
                text-decoration: none;
              }
            }

            &.location-box {
              .map-inner {
                border-bottom: 1px solid $cuColorc8;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 5px 0;

                &:first-child {
                  padding-top: 0;
                }

                &:last-child {
                  border-bottom: 0;
                  padding-bottom: 0;
                }

                p {
                  @include linebreak1();
                }

                span {
                  @include font1220();
                  color: $darkGreen;
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                  white-space: pre;
                  margin-left: 5px;

                  svg {
                    margin-left: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .review-data {
      ul {
        padding: 0;
        margin: 0;

        &.hide-border {
          li:last-child {
            border-bottom: 0;
            padding-bottom: 0;
          }
        }

        li {
          padding: 15px 0;
          border-bottom: 1px solid $cuColorc8;
          display: block;

          &:first-child {
            padding-top: 0;
          }

          .review-box {
            display: flex;
            align-items: flex-start;
            width: 100%;
            margin-bottom: 5px;

            .one {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              background-color: $cuColorc8;
              @include bgCover();
            }

            .two {
              width: calc(100% - 120px);
              padding: 0 10px;

              .star-data {
                width: 100%;
                display: flex;
                align-items: center;

                span {
                  display: flex !important;
                }

                .user-select-none {
                  pointer-events: none;
                  user-select: none;
                  display: flex;
                  align-items: center;

                  span {
                    margin-right: 2.5px;
                  }
                }
              }

              p {
                font-weight: 700;
                padding-bottom: 6px;
              }
            }

            .three {
              width: 90px;
              text-align: right;

              span {
                display: block;
                @include font1420();
                font-weight: 600;
                color: $cuColor87;
              }
            }
          }

          p {
            @include font1420();
            font-weight: 600;
            color: $darkGreen;
            margin-bottom: 0;
          }
        }
      }

      .load-more-data {
        margin-top: 40px;
      }
    }

    .all-review-box {
      margin-bottom: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: map-get($mediaSizes, "cu450")) {
        flex-direction: column;
      }

      .rate-sec {
        text-align: center;
        padding: 20px 20px 20px 0;

        h4 {
          font-size: 30px;
          line-height: 56px;
          color: $darkGreen;
          font-weight: 700;
          margin-bottom: 0px;
        }

        .star-data {
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 5px;

          .user-select-none {
            pointer-events: none;
            user-select: none;
            display: flex;
            align-items: center;

            span {
              margin-right: 2px;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }

        p {
          @include font1426();
          color: $darkGreen;
          font-weight: 600;
        }
      }

      .star-sec {
        border-left: 1px solid $cuColorc8;
        padding-left: 34px;

        @media (max-width: map-get($mediaSizes, "cu450")) {
          border-left: none;
          padding-left: 0px;
        }

        .star-data {
          display: flex;
          align-items: center;
          margin-bottom: 2px;

          .user-select-none {
            pointer-events: none;
            user-select: none;
            display: flex !important;
            align-items: center;

            span {
              margin-right: 2px;

              &:last-child {
                margin-right: 0;
              }
            }
          }

          p {
            @include font1426();
            color: $darkGreen;
            font-weight: 700;
            margin-bottom: 0;
            margin-right: 10px;
            min-width: 16px;
            text-align: right;
          }
        }
      }

    }

    .slick-dots {
      width: fit-content;
      margin: auto;
      padding: 5px 6px;
      border-radius: 10px;
      background-color: rgba($black, 0.5);
      display: flex !important;
      left: 0;
      right: 0;
      bottom: 20px;

      li {
        width: 10px;
        height: 10px;
        margin: 0px 2px;
        @include trans3();

        &.slick-active {
          width: 30px;

          button {
            opacity: 1;
          }
        }

        button {
          width: 100%;
          height: 100%;
          background-color: $white;
          border-radius: 5px;

          &::before {
            display: none;
          }
        }
      }
    }
  }

  .main-slider,
  .second-slider {
    .slick-arrow {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      @include all_center();
      background-color: $offWhite;
      z-index: 99;
      @include trans3();

      &:focus {
        background-color: $offWhite;
      }

      &:hover {
        background-color: $primary;

        &::before {
          filter: brightness(0) invert(1);
        }
      }

      &::before {
        content: "" !important;
        height: 8px;
        width: 14px;
        background: url("../../assets/images/icon/select-icon.svg") no-repeat;
        background-size: 100%;
        opacity: 1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        @include trans3();
      }

      &.slick-disabled {
        display: none !important;
      }

      &.slick-prev {
        left: 10px;

        &::before {
          transform: rotate(90deg);
        }
      }

      &.slick-next {
        right: 10px;

        &::before {
          content: "";
          transform: rotate(-90deg);
        }
      }
    }
  }

  .bg-data {
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: contain !important;
    border-radius: 20px;
    height: 70vh;
    max-height: 600px;
    min-height: 400px;
  }
}

.success-modal {
  &.modal-dialog {
    width: calc(100% - 30px);
    margin-left: auto;
    margin-right: auto;

    @media (max-width: map-get($mediaSizes, "mid")) {
      max-width: 400px;
    }

    @media (min-width: map-get($mediaSizes, "sm")) {
      max-width: 400px;
    }
  }

  .modal-content {
    border: 1px solid $cuColor87;
    border-radius: 0px !important;
    box-shadow: none;
  }

  .modal-body {
    padding: 30px 20px 40px;

    p {
      @include font1626();
      font-weight: 600;
      color: $cuColor87;
      margin-bottom: 30px;
    }
  }
}

.noSp-modal {
  &.modal-dialog {
    width: calc(100% - 30px);
    margin-left: auto;
    margin-right: auto;

    @media (max-width: map-get($mediaSizes, "mid")) {
      max-width: 400px;
    }

    @media (min-width: map-get($mediaSizes, "sm")) {
      max-width: 400px;
    }
  }

  .modal-content {
    border: 1px solid $cuColor87;
    border-radius: 10px;
    box-shadow: none;
  }

  .modal-body {
    padding: 30px 20px 40px;

    p {
      @include font1626();
      font-weight: 600;
      color: $cuColor87;
      margin-bottom: 30px;
    }
  }
}

.reply-section {
  .heading {
    @include font2037();
    font-weight: 600;
    color: $darkGreen;
    margin-bottom: 20px;
    text-transform: capitalize;

    &.mb5 {
      margin-bottom: 5px;
    }

    @media (max-width: map-get($mediaSizes, "lg")) {
      @include font1626();
    }
  }

  .reply-box {
    .collaps-icon {
      // color: $primary;
      cursor: pointer;
      background-color: $offWhite;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        font-size: 16px;
      }
    }

    &.mb40 {
      margin-bottom: 40px;

      @media (max-width: map-get($mediaSizes, "lg")) {
        margin-bottom: 25px;
      }
    }

    .paratext {
      @include font1420();
      font-weight: 600;
      color: $cuColor87;
      margin-bottom: 0;

      .selected {
        color: $primary;
        font-weight: 700;
        padding-left: 15px;
      }
    }

    .icon-data {
      display: flex;
      align-items: center;
      gap: 10px;

      @media (max-width: map-get($mediaSizes, "xl")) {
        gap: 8px;
      }

      .icon-i {
        width: 20px;

        @media (max-width: map-get($mediaSizes, "xl")) {
          width: 16px;
        }

        svg {
          max-width: 100%;
        }
      }

      .text-i {
        width: calc(100% - 20px);
        @include font1630();
        font-weight: 700;
        color: $darkGreen;

        @media (max-width: map-get($mediaSizes, "xl")) {
          @include font1420();
          width: calc(100% - 16px);
        }
      }
    }
  }

  .img-box {
    margin: 30px 0;
    text-align: center;

    @media (max-width: map-get($mediaSizes, "lg")) {
      margin: 25px 0;
    }

    .profile-img {
      width: 180px;
      height: 180px;
      border-radius: 50%;
      margin: 0 auto 15px auto;
      background-color: $cuColorc8;
      border: 1px solid $cuColorc8 !important;
      @include bgCover();
    }

    span.text {
      @include font1630();
      color: $darkGreen;
      font-weight: 700;
      margin-bottom: 0;
      text-transform: capitalize;
    }
  }

  .error-data {
    @include font1218();
    color: $danger;
    font-weight: 600;
    display: block;
    text-align: center;
    margin-bottom: 40px;

    @media (max-width: map-get($mediaSizes, "lg")) {
      margin-bottom: 25px;
    }

    &.mt20 {
      margin-top: 20px;
    }
  }

  .question-text {
    @include font2037();
    color: $darkGreen;
    text-align: center;
    font-weight: 500;
    margin-bottom: 20px;

    @media (max-width: map-get($mediaSizes, "lg")) {
      @include font1830();
    }
  }

  .main-box {
    box-shadow: 0 5px 10px rgba($black, 0.1);
    border-radius: 20px;
    padding-block: 20px;
    border: 1px solid rgba($black, 0.1);
  }

  .bottom-btn-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .one {
      width: 150px;

      @media (max-width: map-get($mediaSizes, "cu450")) {
        width: 50%;
        padding-right: 5px;
      }
    }

    .two {
      &.no-width {
        width: unset;
      }

      padding-left: 40px;
      width: calc(100% - 150px);

      @media (max-width: map-get($mediaSizes, "lg")) {
        padding-left: 20px;
      }

      @media (max-width: map-get($mediaSizes, "cu450")) {
        width: 50%;
        padding-left: 5px;
      }
    }
  }

  .contact-box {
    padding: 20px 18px;
    border-radius: 20px;
    background-color: $offWhite;
    max-width: 354px;
    margin: 0 auto 40px;

    .heading {
      @include font2037();
      font-weight: 600;
      color: $darkGreen;
      margin-bottom: 17px;
      text-transform: capitalize;

      @media (max-width: map-get($mediaSizes, "lg")) {
        @include font1626();
      }
    }

    ul {
      padding: 0;
      margin: 0;

      li {
        display: block;
        padding: 10px 0;
        border-bottom: 1px solid $cuColorc8;

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          border-bottom: 0;
          padding-bottom: 0;
        }

        .exp-box {
          display: flex;
          align-items: flex-start;

          .one {
            width: 20px;
          }

          .two {
            padding-left: 15px;
            width: calc(100% - 20px);

            p,
            a {
              @include font1426();
              color: $darkGreen;
              font-weight: 600;
              margin-bottom: 0;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

// RTL CSS
[dir="rtl"] {
  .service-section {
    .service-section-data {
      .service-details-left-data {
        padding-left: 40px;
        padding-right: 0;

        @media (max-width: map-get($mediaSizes, "xl")) {
          padding-left: 20px;
        }

        @media (max-width: map-get($mediaSizes, "lg")) {
          padding-left: 0;
        }

        .service-details-header {
          .replace-data {
            text-align: left;
          }
        }
      }

      .choose-provider-data {
        ul.choose-ul {
          li.choose-li {
            .inner-provider-card {
              .two {
                &.profile-card-two {
                  width: calc(100% - 250px);
                  margin: 0 auto;
                }

                ul {
                  li {
                    .name-data {
                      svg {
                        margin-left: 0;
                        margin-right: 5px;
                      }
                    }

                    .star-data {
                      .user-select-none {
                        margin-left: 2.5px;
                        margin-right: 0;

                        span {
                          margin-left: 2.5px;
                          margin-right: 0;
                        }
                      }
                    }
                  }
                }
              }

              .three {
                svg {
                  transform: rotate(180deg);
                }
              }
            }
          }
        }
      }

      .brief-card {
        .listing-attachments {
          padding-right: 0;
          padding-left: 10px;
        }

        .attach-btns.with-white {
          padding: 5px 11px 5px 17px;

          svg {
            margin-left: 10px;
            margin-right: 0;
          }
        }
      }

      .service-left-data {
        padding-right: 0px;
        padding-left: 40px;

        @media (max-width: map-get($mediaSizes, "xl")) {
          padding-left: 20px;
        }

        @media (max-width: map-get($mediaSizes, "lg")) {
          padding-left: 0;
        }

        .inner-service-data {
          .service-inner-listing {
            border-right: 1px solid $primary;
            border-left: 0;

            @media (max-width: map-get($mediaSizes, "md")) {
              border-right: 0;
            }

            ul {
              li {
                .service-inner-list-card {
                  .two {
                    padding: 0 10px 0 0;
                  }
                }
              }
            }
          }
        }
      }

      .order-summary-data {
        @media (max-width: map-get($mediaSizes, "lg")) {
          padding-right: 15px;
          padding-left: 0;
        }

        .scroll-data {
          width: calc(100% + 10px);
          padding-left: 10px;
          padding-right: 0;

          .order-box {
            .outer-ul {
              padding: 0;

              li {
                &.inner-li {
                  padding-right: 20px;
                  padding-left: 0;

                  &::before {
                    content: "";
                    left: unset;
                    right: 5px;
                  }
                }
              }
            }

            p {
              margin-bottom: 0;

              &.head-text {
                @include font1426();
                color: $lightGreen;
                font-weight: 600;
                margin-bottom: 5px;
                text-transform: capitalize;
              }

              &.list-text {
                @include font1630();
                color: $cuColor87;
                font-weight: 700;
                margin-bottom: 5px;
                text-transform: capitalize;
              }
            }
          }

          .order-provider-box {
            ul {
              li {
                .name-data {
                  svg {
                    margin-right: 5px;
                    margin-left: 0;
                  }
                }

                .star-data {
                  .user-select-none {
                    margin-left: 2.5px;
                    margin-right: 0;

                    span {
                      margin-left: 2.5px;
                      margin-right: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .service-main-card {
        &.full {
          .two {
            padding-right: 20px;
            padding-left: 0;
          }
        }

        .three {
          transform: rotate(180deg);
        }
      }

      .head-data {
        .back-arrow {
          margin-left: 10px;
          margin-right: 0;

          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .provider-modal {
    .modal-body {
      .modal-body-scroll {
        padding-left: 30px;
        padding-right: 0;
      }

      .profile-box {
        .name-data {
          svg {
            margin-left: 0;
            margin-right: 5px;
          }
        }

        .rate-data {
          .comment-data {
            padding-right: 0;
            padding-left: 13px;
            padding-top: 3px;
          }

          .user-select-none {
            margin-left: 2.5px;
            margin-right: 0;

            span {
              margin-left: 2.5px;
              margin-right: 0;
            }
          }

          .text-i.ispan {
            padding-right: 10px;
            padding-left: 0;
          }
        }
      }

      .expertise-data {
        ul {
          li {
            .exp-box {
              .two {
                padding-left: 0;
                padding-right: 15px;
              }
            }
          }
        }
      }

      .education-data {
        ul {
          li {
            .exp-box {
              .two {
                padding-left: 0;
                padding-right: 15px;
              }
            }
          }
        }
      }

      .contact-data {
        ul {
          li {
            .exp-box {
              .two {
                padding-left: 0;
                padding-right: 15px;
              }

              &.location-box {
                .map-inner {
                  span {
                    margin-right: 5px;
                    margin-left: 0;

                    svg {
                      margin-left: 0;
                      margin-right: 5px;
                      transform: rotate(180deg);
                    }
                  }
                }
              }
            }
          }
        }
      }

      .review-data {
        ul {
          li {
            .review-box {
              .two {
                .star-data {
                  .user-select-none {
                    span {
                      margin-right: 0;
                      margin-left: 2.5px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .all-review-box {
        .rate-sec {
          text-align: center;
          padding: 20px 0 20px 20px;

          .star-data {
            .user-select-none {
              span {
                margin-left: 2px;
                margin-right: 0px;

                &:last-child {
                  margin-left: 0;
                }
              }
            }
          }
        }

        .star-sec {
          border-left: 0;
          border-right: 1px solid $cuColorc8;
          padding-left: 0;
          padding-right: 34px;

          @media (max-width: map-get($mediaSizes, "cu450")) {
            padding-right: 0px;
            border-right: 0px;
          }

          .star-data {
            .user-select-none {
              span {
                margin-left: 2px;
                margin-right: 0px;

                &:last-child {
                  margin-left: 0;
                }
              }
            }

            p {
              margin-right: 0;
              margin-left: 10px;
              text-align: left;
            }
          }
        }
      }
    }

    .main-slider,
    .second-slider {
      .slick-arrow {
        &.slick-prev {
          right: 10px;
          left: unset;

          &::before {
            transform: rotate(-90deg);
          }
        }

        &.slick-next {
          left: 10px;
          right: unset;

          &::before {
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  .reply-section {
    .bottom-btn-box {
      .one {
        @media (max-width: map-get($mediaSizes, "cu450")) {
          padding-right: 0px;
          padding-left: 5px;
        }
      }

      .two {
        padding-right: 40px;
        padding-left: 0px;

        @media (max-width: map-get($mediaSizes, "lg")) {
          padding-right: 20px;
          padding-left: 0px;
        }

        @media (max-width: map-get($mediaSizes, "cu450")) {
          padding-right: 5px;
          padding-left: 0px;
        }
      }
    }

    .contact-box {
      ul {
        li {
          .exp-box {
            .two {
              direction: ltr;
              padding-left: 0px;
              padding-right: 15px;
            }
          }
        }
      }
    }
  }
}