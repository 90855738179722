@import "../../assets/scss/variable";

// review
.service-details-left-data {
  h2.heads-data {
    @include font2037();
    font-weight: 600;
    color: $primary;
    margin-bottom: 40px;
    text-align: center;
    @media (max-width: map-get($mediaSizes, "lg")) {
      @include font1626();
    }
  }
}
.review-left-data {
  border: 1px solid $primary;
  padding: 20px;
  border-radius: 20px;
  h4.heading {
    @include font1630();
    font-weight: 600;
    color: $darkGreen;
    margin-bottom: 10px;
    @media (max-width: map-get($mediaSizes, "lg")) {
      @include font1626();
    }
  }
  textarea.form-control,
  input {
    &:disabled {
      background-color: $white !important;
      cursor: not-allowed;
    }
  }
  button:disabled {
    cursor: not-allowed !important;
  }
  .stars-data {
    span span {
      margin-right: 15px;
    }
  }
}

// RTL CSS
[dir="rtl"] {
  .review-left-data {
    .stars-data {
      span span {
        margin-left: 15px;
        margin-right: 0;
      }
    }
  }
}
