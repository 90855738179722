@import "../../assets/scss/variable";

// BANNER SECTION
.banner-section {
  width: 100%;
  background-color: $primary;
  padding: 140px 0 60px 0;
  

  @media (max-width: map-get($mediaSizes, "xxl")) {
    padding: 96px 0 60px;
  }

  @media (max-width: map-get($mediaSizes, "md")) {
    height: unset;
    padding: 80px 0 60px;
  }

  .main-logo {
    @media (max-width: map-get($mediaSizes, "md")) {
      width: 110px;
    }
  }

  .banner-section-data {
    display: flex;
    align-items: center;
    width: 100%;

    @media (max-width: map-get($mediaSizes, "md")) {
      flex-direction: column-reverse;
    }

    .left-data {
      width: 480px;

      @media (max-width: map-get($mediaSizes, "xl")) {
        width: 420px;
      }

      @media (max-width: map-get($mediaSizes, "lg")) {
        width: 340px;
      }

      @media (max-width: map-get($mediaSizes, "md")) {
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
      }

      h1 {
        font-size: 26px;
        line-height: 1.2;
        font-weight: 700;
        text-transform: uppercase;
        color: $offWhite;
        margin-bottom: 0px;

        // @media (max-width: map-get($mediaSizes, "xxl")) {
        //   font-size: 56px;
        // }

        // @media (max-width: map-get($mediaSizes, "xl")) {
        //   font-size: 48px;
        // }

        // @media (max-width: map-get($mediaSizes, "lg")) {
        //   font-size: 40px;
        // }

        // @media (max-width: map-get($mediaSizes, "md")) {
        //   font-size: 36px;
        // }
      }

      p {
        @include font1620();
        color: $offWhite;
        font-weight: 600;
        margin: 30px 0 30px 0;
        max-width: 400px;

        @media (max-width: map-get($mediaSizes, "xl")) {
          margin: 30px 0;
        }

        @media (max-width: map-get($mediaSizes, "md")) {
          max-width: unset;
          margin: 25px 0;
        }
      }

      .btn {
        max-width: 400px;
        width: 100%;

        @media (max-width: map-get($mediaSizes, "md")) {
          max-width: unset;
        }
      }
    }

    .right-data {

      width: calc(100% - 480px);
      padding-left: 120px;

      img {
        width: 500px;
      }

      @media (max-width: map-get($mediaSizes, "xl")) {
        padding-left: 50px;
        width: calc(100% - 420px);
      }

      @media (max-width: map-get($mediaSizes, "lg")) {
        width: calc(100% - 340px);
        padding-left: 30px;

      }

      @media (max-width: map-get($mediaSizes, "md")) {
        width: 100%;
        max-width: 400px;
        margin: 30px auto 20px;
        padding-left: 0px;
      }
    }
  }
}

// BLOG SECTION
.blog-section {
  padding: 65px 0;

  
  .blog-wrapper {
    display: block;
    // grid-template-columns: repeat(2, 1fr);
    // block-size: 1%;
    // gap: 100px;

    .slick-slider {
      width: 100%;
      position: relative;
      

      &.set-design {

        &::before,
        &::after {
          position: absolute;
          content: "";
          height: 60px;
          width: 400%;
          background-color: $white;
          top: -17px;
          z-index: 999;
        }

        &::before {
          right: 100%;
        }

        &::after {
          left: 100%;
        }
      }
    }

    .slick-arrow {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      @include all_center();
      background-color: $offWhite;
      z-index: 99;
      @include trans3();

      &:focus {
        background-color: $offWhite;
      }

      &:hover {
        background-color: $primary;

        &::before {
          filter: brightness(0) invert(1);
        }
      }

      &::before {
        content: "" !important;
        height: 6px;
        width: 10px;
        background: url("../../assets/images/icon/select-icon.svg") no-repeat;
        background-size: 100%;
        opacity: 1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        @include trans3();
      }

      &.slick-disabled {
        display: none !important;
      }

      &.slick-prev {
        left: 0;

        &::before {
          transform: rotate(90deg);
        }
      }

      &.slick-next {
        right: 0;

        &::before {
          content: "";
          transform: rotate(-90deg);
        }
      }
    }

    .slick-list {
      overflow: hidden overlay;
      @include scroll_vertical_none();
      .slick-track {
        display: flex;
        gap: 10px;
      }
    }

    @media (max-width: map-get($mediaSizes, "xl")) {
      grid-template-columns: repeat(1, 1fr);
      gap: 100px;
    }

    img {
      width: 100%;
      height: 100%;
    }

    .detail-box {
      box-shadow: 0px 5px 10px rgba($black, 0.2);
      border-radius: 0 15px 15px 0;
      padding: 16px 24px;
      @media (max-width: map-get($mediaSizes, "lg")) {
        padding: 16px 12px;
      }

      .title {
        color: $primary;
        @include font1830();
        margin-bottom: 16px;
        font-weight: 700;
        @media (max-width: map-get($mediaSizes, "lg")) {
          font-size: 16px;
        }
        @media (max-width: map-get($mediaSizes, "sm")) {
          font-size: 15px;
        }
      }

      p {
        @include font1420();
        // @include linebreak5();
        color: $cuColor87;
        font-weight: 600;
      }
    }
  }

  @media (max-width: map-get($mediaSizes, "xxl")) {
    padding: 45px 0;
  }

  .card-main {
    height: 100%;
    max-width: 600px;
    margin: auto;
  }

  @media (max-width: map-get($mediaSizes, "sm")) {
    img {
      object-fit: cover;
      object-position: center;
    }
  }

  img.rad {
    border-radius: 15px 0 0 15px;
  }
}


.contact-modal {
  padding: 8px 20px;

  .modal-content {
    border: none;
    max-width: 300px;
    margin: 0 auto;
    border-radius: 32px;
    padding: 16px 12px;
  }

  h2 {
    color: $darkGreen;
    font-weight: 700;
  }

  a {
    color: $primary;
    font-weight: 700;
    font-size: 16px;

  }

  span {
    color: $darkGreen;
    font-weight: 600;
    font-size: 16px;
  }

  .contact-model-icon {
    color: $primary;
    fill: $primary;
  }

  .modal-header {
    border-bottom: 0;
  }

  .close {
    top: 0;
    right: 5px;
    width: 40px;
    height: 40px;
    border: 0;
    border-radius: 50px;
    color: $primary;
    font-size: 28px;
    line-height: 40px;
    background: transparent;
  }
}

.preview-model {
  .modal-content {
    border: none;
    // max-width: 300px;
    margin: 0 auto;
    border-radius: 32px;
    padding: 16px 12px;
  }

  h2 {
    color: $darkGreen;
    font-weight: 700;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    color: $darkGreen;
    font-weight: 600;
  }

  .close {
    top: 0;
    right: 5px;
    width: 40px;
    height: 40px;
    border: 0;
    border-radius: 50px;
    color: $primary;
    font-size: 28px;
    line-height: 40px;
    background: transparent;
  }
}

// RTL CSS
[dir="rtl"] {

  // BANNER SECTION
  .banner-section {
   
    @media (max-width: map-get($mediaSizes, "xl")) {
      padding: 90px 0 60px;
    }

    .banner-section-data {
      .right-data {
        padding-right: 30px;
        padding-left: 0;
      }
    }
  }

  // BLOG SECTION
  .blog-section {
    padding: 50px 0;


    .slick-arrow {
      &.slick-prev {
        right: 0px;
        left: unset;

        &::before {
          transform: rotate(-90deg);
        }
      }

      &.slick-next {
        left: 0px;
        right: unset;

        &::before {
          transform: rotate(90deg);
        }
      }
    }

    @media (max-width: map-get($mediaSizes, "xxl")) {
      padding: 40px 0;
    }

    img.rad {
      border-radius: 0 15px 15px 0;
    }

    .detail-box {
      border-radius: 15px 0 0 15px;
    }
    .card-main{
      direction: rtl;
    }
  }

  .contact-modal {
    .close {
      position: absolute;
      top: 0;
      left: 5px;
      right: unset;

    }
  }

  .preview-model {
    .close {
      position: absolute;
      top: 0;
      left: 5px;
      right: unset;

    }
  }
}