@import "../../assets/scss/variable";

// MYORDER SECTION
.myorder-section {
  padding: 40px 0 0;

  .order-tab-header {
    width: 100%;

    button {
      &.br8 {
        border-radius: 8px;
      }
    }

    ul {
      padding: 0;
      margin: 0 0 50px 0;
      border: 0;
      background-color: $gray;
      border-radius: 20px;
      min-height: 40px;

      &.mb30 {
        margin: 0 0 10px 0;
      }

      li {
        display: inline-block;
        min-width: 50%;
        max-width: 50%;
        border: 0;

        .nav-link {
          border: 0;
          min-height: 40px;
          border-radius: 20px;
          text-align: center;
          color: $cuColor87;
          @include font1630();
          font-weight: 600;
          padding: 5px 20px;
          background-color: transparent;
          cursor: pointer;
          @include trans3();

          &.active {
            background-color: $lightGreen;
            color: $white;

            &:hover {
              color: $white;
            }
          }

          &:hover {
            color: $darkGreen;
          }
        }
      }
    }
  }

  .order-tab-body {
    .order-service-data {
      margin-bottom: 40px;
      padding: 0;

      .title-data {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        @include font1630();
        color: $lightGreen;
        font-weight: 600;

        svg {
          margin-right: 10px;
        }

        .filter-tab {
          svg {
            margin-right: 0px;
          }
        }
      }

      .order-service-ul {
        padding: 0 5px 10px;
        margin: 0;
        overflow-y: auto;
        max-height: 450px;
        @include scroll_vertical_primary();

        &.scroll-none {
          padding: 0;
          overflow-y: unset;
          max-height: unset;
        }

        .order-service-li {
          display: block;
          margin-bottom: 20px;

          // &.active {
          //   .order-service-card {
          //     background: rgba($lightGreen, 0.2) !important;
          //   }
          // }

          &:last-child {
            margin-bottom: 0;
          }

          .order-service-card {
            padding: 10px 20px 15px;
            border-radius: 20px;
            box-shadow: 0px 5px 10px rgba($black, 0.1);
            display: flex;
            align-items: center;
            width: 100%;
            min-height: 120px;

            &.show-border {
              border: 2px solid $primary;
            }

            .one {
              width: calc(100% - 60px);
              overflow: hidden;

              .grid-data {
                display: grid;
                grid-template-columns: repeat(auto-fit,
                    minmax(calc(33.33% - 15px), 1fr));
                gap: 15px;

                @media (max-width: map-get($mediaSizes, "md")) {
                  grid-template-columns: repeat(auto-fit,
                      minmax(calc(50% - 15px), 1fr));
                }

                @media (max-width: map-get($mediaSizes, "sm")) {
                  grid-template-columns: repeat(1, 1fr);
                }
              }

              ul.list-inner {
                padding: 0;
                margin: 0;

                li {
                  display: block;

                  span.main {
                    @include font1630();
                    font-weight: 700;
                    color: $darkGreen;
                    @include linebreak1();
                    word-break: break-all;

                    @media (max-width: map-get($mediaSizes, "lg")) {
                      @include font1426();
                    }
                  }

                  .profile-rate-data {
                    display: flex;
                    align-items: center;
                    width: 100%;

                    .first {
                      width: 60px;
                      height: 60px;
                      background-color: $cuColorc8;
                      border-radius: 50%;
                      overflow: hidden;
                      border: 1px solid $cuColorc8 !important;
                      @include bgCover();

                      @media (max-width: map-get($mediaSizes, "sm")) {
                        width: 50px;
                        height: 50px;
                      }
                    }

                    .second {
                      width: calc(100% - 60px);
                      padding-left: 15px;
                      text-transform: capitalize;

                      @media (max-width: map-get($mediaSizes, "sm")) {
                        width: calc(100% - 50px);
                        padding-left: 10px;
                      }

                      .star-data {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        margin-top: -8px;

                        .user-select-none {
                          pointer-events: none;
                          user-select: none;
                          display: flex;
                          align-items: center;
                          margin-right: 2px;

                          span {
                            margin-right: 2px;
                          }
                        }

                        .comment-data {
                          color: $cuColor87;
                          font-weight: 700;
                          @include font1220();
                        }
                      }
                    }
                  }
                }
              }
            }

            .two {
              width: 60px;

              .arrow-icon {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                @include all_center();
                background-color: $offWhite;
                color: $darkGreen;
                margin-left: auto;
              }

              .status-box {
                margin-left: auto;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                flex-direction: column;

                .icon-bg {
                  width: 30px;
                  height: 30px;
                  border-radius: 8px;
                  background-color: $primary;
                  @include all_center();
                  margin: 0 auto;
                  color: $white;
                }

                span {
                  @include font1426();
                  font-weight: 600;
                  color: $cuColor87;
                }
              }
            }

            .label-text {
              display: block;
              @include font1426();
              font-weight: 600;
              color: $darkGreen;
              margin-bottom: 10px;
            }
          }
        }
      }
    }

    .inner-center {
      min-height: 70vh;
      @include all_center();
    }
  }
}



// RTL CSS
[dir="rtl"] {
  .myorder-section {
    .order-tab-body {
      .order-service-data {
        .title-data {
          svg {
            margin-left: 10px;
            margin-right: 0;
          }

          .filter-tab {
            svg {
              margin-left: 0px;
            }
          }
        }

        .order-service-ul {
          padding: 0 0 10px 5px;

          .order-service-li {
            .order-service-card {
              .one {
                ul.list-inner {
                  li {
                    .profile-rate-data {
                      .second {
                        padding-left: 0;
                        padding-right: 15px;

                        @media (max-width: map-get($mediaSizes, "sm")) {
                          padding-left: 0;
                          padding-right: 10px;
                        }

                        .star-data {
                          .user-select-none {
                            margin-left: 2px;
                            margin-right: 0;

                            span {
                              margin-left: 2px;
                              margin-right: 0;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}