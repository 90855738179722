@import "../../assets/scss/variable";

// BANNER SECTION
.banner-home-section {
  padding-bottom: 30px;

  a.full-link {
    display: block;
  }


  .slick-slide {
    float: left !important;

    div,
    a,
    img {
      outline: none !important;
    }
  }


  .slick-dots {
    width: fit-content;
    margin: auto;
    padding: 5px 6px;
    border-radius: 10px;
    background-color: rgba($black, 0.5);
    display: flex !important;
    left: 0;
    right: 0;
    bottom: 20px;


    li {
      width: 10px;
      height: 10px;
      margin: 0px 2px;
      @include trans3();

      &.slick-active {
        width: 30px;

        button {
          opacity: 1;
        }
      }

      button {
        width: 100%;
        height: 100%;
        background-color: $white;
        border-radius: 5px;

        &::before {
          display: none;
        }
      }
    }
  }
}

// JOB SECTION
.job-section {
  padding: 0 0 20px 0;

  .job-section-data {
    h2 {
      @include font4050();
      font-weight: 700;
      color: $darkGreen;
    }
  }

  .job-box-data {
    margin-top: 20px;
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: map-get($mediaSizes, "md")) {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    }

    .job-box {
      display: flex;
      position: relative;
      text-decoration: none;
      justify-content: center;

      &:first-child {
        img {
          position: absolute;
          right: 0;
          max-width: 100px;
        }
      }

      &:last-child {
        img {
          max-width: 100px;
        }
      }

      @media (max-width: map-get($mediaSizes, "md")) {
        width: 500px;
        margin: 0 auto;
      }

      @media (max-width: map-get($mediaSizes, "cu450")) {
        width: 100%;
      }

      .img-box {
        width: 40%;
        background-color: $offWhite;
        padding-block: 18px;
        border-radius: 20px 0 0 20px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .one {
        width: 60%;
        max-width: 200px;
        box-shadow: 5px 5px 10px rgba($black, 0.1);
        padding: 16px;
        border-radius: 0 20px 20px 0;

        p {
          @include font1426();
          font-weight: 600;
          color: $cuColor87;
        }

        span {
          @include font1630();
          font-weight: 700;
          color: $lightGreen;

          @media (max-width: map-get($mediaSizes, "xl")) {
            @include font1426();
          }
        }

        .order-btn {
          text-align: right;
        }
      }

      img {
        width: 100%;
        max-width: 70px;
      }
    }
  }
}

// HOME HEADER

.home-header {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  background: $white;
  z-index: 9;

  .navbar {
    padding: 15px 0px;
    min-height: 60px;

    .slick-slider {
      width: 100%;
      position: relative;

      &.set-design {

        &::before,
        &::after {
          position: absolute;
          content: "";
          height: 60px;
          width: 400%;
          background-color: $white;
          top: -17px;
          z-index: 999;
        }

        &::before {
          right: 100%;
        }

        &::after {
          left: 100%;
        }
      }
    }

    .slick-arrow {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      @include all_center();
      background-color: $offWhite;
      z-index: 99;
      @include trans3();

      &:focus {
        background-color: $offWhite;
      }

      &:hover {
        background-color: $primary;

        &::before {
          filter: brightness(0) invert(1);
        }
      }

      &::before {
        content: "" !important;
        height: 6px;
        width: 10px;
        background: url("../../assets/images/icon/select-icon.svg") no-repeat;
        background-size: 100%;
        opacity: 1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        @include trans3();
      }

      &.slick-disabled {
        display: none !important;
      }

      &.slick-prev {
        left: 0;

        &::before {
          transform: rotate(90deg);
        }
      }

      &.slick-next {
        right: 0;

        &::before {
          content: "";
          transform: rotate(-90deg);
        }
      }
    }

    .slick-list {
      overflow: hidden overlay;
      overflow: visible;
      // z-index:-1;
      @include scroll_vertical_none();
      text-align: center;
    }

    .home-header-dropdown {
      position: relative;
      width: 100% !important;

      &.show {
        .nav-link span {
          color: $darkGreen;
        }
      }

      .nav-link span {
        @include font1620();
        color: $cuColor87;
        font-weight: 600;
      }

      .dropdown-menu {
        padding: 10px 10px;
        min-width: 200px;
        max-width: 200px;
        border-radius: 20px;
        border: 0 !important;
        box-shadow: 0px 5px 10px rgba($black, 0.2);
        z-index: 9999;
        transform: unset !important;
        left: 0 !important;
        right: 0 !important;
        margin: 35px auto 0 !important;
        overflow: hidden;

        .inner-drop-body {
          overflow-y: auto;
          width: 100%;
          max-height: 200px;
          width: calc(100% + 5px);
          padding: 10px 15px 10px 10px;
          @include scroll_vertical_main_hover();
        }

        .dropdown-item {
          padding: 7px 0 8px 0;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          border-bottom: 1px solid $cuColorc8;
          color: $darkGreen !important;
          font-weight: 600;
          cursor: pointer;
          @include trans3();

          &:focus,
          &:hover,
          &:active {
            background-color: transparent !important;
            color: $lightGreen !important;

            span,
            a {
              color: $lightGreen;
            }
          }

          &.disabled {
            color: $cuColor87 !important;
            justify-content: center;
            border-bottom: 0;

            &:focus,
            &:hover,
            &:active {
              background-color: transparent !important;
              color: $cuColor87 !important;
            }
          }

          a,
          span {
            text-align: left;
            color: $darkGreen !important;
            font-weight: 600;
            @include trans3();
          }
        }
      }
    }
  }
}

// RTL CSS
[dir="rtl"] {

  // JOB SECTION
  .job-section {
    .job-box-data {
      .job-box {
        &:first-child {
          img {
            position: absolute;
            right: unset;
            left: 0;
            max-width: 100px;
          }
        }

        .img-box {
          border-radius: 0 20px 20px 0;

          img {
            transform: scaleX(-1);
          }
        }

        .one {
          border-radius: 20px 0 0 20px;

          .order-btn {
            text-align: left;
          }
        }

      }
    }
  }

  .home-header {
    .slick-arrow {
      &.slick-prev {
        right: 0px;
        left: unset;

        &::before {
          transform: rotate(-90deg);
        }
      }

      &.slick-next {
        left: 0px;
        right: unset;

        &::before {
          transform: rotate(90deg);
        }
      }
    }
  }
}