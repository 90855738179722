@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&display=swap");

$primary: #3cb4b4;
$danger: #e64646;
$warning: #ffd700;
$black: #000000;
$offWhite: #ebffff;
$darkGreen: #006464;
$lightGreen: $primary;
$white: #ffffff;
$cuColor87: #878787;
$cuColorc8: #c8c8c8;
$gray: #f0f0f0;
$cuColorF2: #f2f2f2;
$lightGray: #858282;
$fadedBlack:#1A1D1F;
$gloabal_width: 1190px;
$primaryFont: "Cairo", sans-serif;
// mediaSizes
$mediaSizes: (
  "cu450": 450px,
  "sm": 576px,
  "mid": 700px,
  "md": 767px,
  "lg": 992px,
  "xl": 1200px,
  "xxl": 1500px,
);

@mixin trans3 {
  transition: 0.3s ease-in-out;
}

@mixin all_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

// font
@mixin font1220 {
  font-size: 12px;
  line-height: 20px;
}

@mixin font1218 {
  font-size: 12px;
  line-height: 18px;
}

@mixin font1420 {
  font-size: 14px;
  line-height: 20px;
}

@mixin font1426 {
  font-size: 14px;
  line-height: 26px;
}

@mixin font1620 {
  font-size: 16px;
  line-height: 20px;
}

@mixin font1630 {
  font-size: 16px;
  line-height: 30px;
}

@mixin font1626 {
  font-size: 16px;
  line-height: 26px;
}

@mixin font1830 {
  font-size: 18px;
  line-height: 30px;
}

@mixin font2020 {
  font-size: 20px;
  line-height: 20px;
}

@mixin font2037 {
  font-size: 20px;
  line-height: 37px;
}

@mixin font2230 {
  font-size: 22px;
  line-height: 30px;
}

@mixin font2528 {
  font-size: 25px;
  line-height: 28px;
}

@mixin font2540 {
  font-size: 25px;
  line-height: 40px;
}

@mixin font3040 {
  font-size: 30px;
  line-height: 40px;
}

@mixin font3555 {
  font-size: 35px;
  line-height: 55px;
}

@mixin font4050 {
  font-size: 40px;
  line-height: 50px;
}

@mixin font4555 {
  font-size: 45px;
  line-height: 55px;
}

@mixin font4584 {
  font-size: 45px;
  line-height: 84px;
}

iframe {
  display: none;
}

@mixin scroll_vertical_none {
  &::-webkit-scrollbar {
    width: 0px;
    height: 0;
    background-color: $offWhite;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: $white;
  }
}

@mixin scroll_vertical_primary {
  &::-webkit-scrollbar {
    width: 4px;
    background-color: $primary;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: $white;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $primary;
  }
}

@mixin html_scroll {
  &::-webkit-scrollbar {
    width: 8px;
    background-color: $primary;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: $white;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: $primary;
  }
}

@mixin body_scroll {
  &::-webkit-scrollbar {
    width: 8px;
    background-color: $primary;
  }

  &:hover::-webkit-scrollbar {
    width: 8px;
    background-color: $primary;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: $white;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: $primary;
  }

  &:hover::-webkit-scrollbar-thumb:hover {
    background-color: $primary;
  }
}

@mixin scroll_vertical_main_hover {
  &::-webkit-scrollbar {
    width: 4px;
    background-color: $primary;
  }

  &:hover::-webkit-scrollbar {
    width: 4px;
    background-color: $primary;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: $white;
  }

  &:hover::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: $primary;
  }

  &:hover::-webkit-scrollbar-thumb:hover {
    border-radius: 2px;
    background-color: $primary;
  }
}

@mixin scroll_vertical_white_hover {
  &::-webkit-scrollbar {
    width: 4px;
    background-color: $primary;
  }

  &:hover::-webkit-scrollbar {
    width: 4px;
    background-color: $primary;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: $offWhite;
  }

  &:hover::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: $primary;
  }

  &:hover::-webkit-scrollbar-thumb:hover {
    border-radius: 2px;
    background-color: $primary;
  }
}

@mixin linebreak1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

@mixin linebreak2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

@mixin linebreak3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

@mixin linebreak5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

@mixin bgCover {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center center !important;
}