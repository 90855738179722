@import "./variable";
@import "./shimmer";
@import "./common.rtl";
@import "./_phone";

*,
body {
  padding: 0;
  margin: 0;
  font-family: $primaryFont;
}

body.modal-open {
  padding-right: 8px !important;
}

html {
  @include html_scroll();
}

.pointer {
  cursor: pointer;
}

.all_center {
  @include all_center();
}

.text-primary1 {
  color: $darkGreen;
}

.op0 {
  opacity: 0;
  visibility: hidden;
}

// modal
.modal-backdrop {
  &.opw1 {
    background-color: transparent;
    opacity: 1 !important;
    backdrop-filter: blur(15px);
  }
}

// container
.container,
.container-lg {
  @media (min-width: map-get($mediaSizes, "xxl")) {
    max-width: $gloabal_width !important;
  }
}

// btn css
.btn {
  &.cu-btn {
    min-height: 40px;
    padding: 5px 20px;
    font-weight: 600;
    @include font1620();

    &.cu-sm-btn {
      min-height: 30px;
      padding: 4px 20px;
      @include font1220();
    }

    &.btn-warn {
      background-color: $warning;
      color: $darkGreen;
      border: 1px solid $warning;

      &:hover {
        background-color: $darkGreen;
        color: $warning;
        border: 1px solid $darkGreen;
      }
    }

    &.btn-main {
      background-color: $primary;
      color: $white;
      border: 1px solid $primary;

      &:hover {
        background-color: $white;
        color: $primary;
        border: 1px solid $primary;
      }
    }

    &.btn-main-outline {
      background-color: $white;
      color: $primary;
      border: 1px solid $primary;

      &:hover {
        background-color: $primary;
        color: $white;
        border: 1px solid $primary;
      }
    }

    &.btn-main-light {
      background-color: $offWhite;
      color: $primary;
      border: 1px solid $offWhite;

      &:hover {
        background-color: $white;
        color: $primary;
        border: 1px solid $primary;
      }
    }

    &.btn-main-white {
      background-color: $white;
      color: $primary;
      border: 1px solid $white;

      &:hover {
        background-color: $white;
        color: $primary;
        border: 1px solid $primary;
      }
    }

    &.btn-secondary {
      background-color: $gray;
      color: $danger;
      border: 1px solid $gray;

      &:hover {
        background-color: rgba($white, 1);
        color: $danger;
        border: 1px solid $primary;
      }
    }

    &.btn-delete {
      background-color: $danger;
      color: $white;
      border: 1px solid $danger;

      &:hover {
        background-color: $white;
        color: $danger;
        border: 1px solid $danger;
      }
    }

    &.btn-dis-new {
      background-color: $cuColor87;
      color: $white;
      border: 1px solid $cuColor87;

      &:hover {
        background-color: $white;
        color: $cuColor87;
        border: 1px solid $cuColor87;
      }
    }

    &.btn-icon {
      width: 40px;
      height: 40px;
      padding: 0;
      @include all_center();
      background-color: $white;
      color: $lightGreen;
      border: 1px solid $cuColor87;

      &:hover {
        background-color: $lightGreen;
        color: $white;
        border: 1px solid $lightGreen;
      }
    }

    &.br20 {
      border-radius: 20px;
    }

    &.br15 {
      border-radius: 15px;
    }

    &.min150 {
      min-width: 150px;
    }

    &.min120 {
      min-width: 120px;
    }

    &.max400 {
      max-width: 400px;
      margin: 25px auto 0;
    }

    &.min400 {
      min-width: 400px;

      @media (max-width: map-get($mediaSizes, "sm")) {
        min-width: 200px;
      }
    }
  }
}

// form
.mb40 {
  margin-bottom: 40px;
}

.mt20 {
  margin-top: 20px;
}

.mb20 {
  margin-bottom: 20px;
}

.field-group {
  &.mb40 {
    margin-bottom: 40px;
  }

  &.mb20 {
    margin-bottom: 20px;
  }
}

.cu-form-group {
  position: relative;

  &.mb20 {
    margin-bottom: 20px;
  }

  &.mb15 {
    margin-bottom: 15px;
  }

  &.mb40 {
    margin-bottom: 40px;
  }

  textarea.form-control {
    height: 150px;
    resize: none;
    @include scroll_vertical_primary();
  }

  .form-control {
    height: 40px;
    border-radius: 20px;
    border: 1px solid $cuColorc8;
    padding: 10px 25px;
    font-weight: 600;
    @include trans3();
    @include font1420();
    color: $primary;

    &:focus {
      border-color: $primary;
      outline: none;
      box-shadow: none;
      color: $primary;
    }

    &:hover {
      border-color: $primary;
    }
  }

  &.telephone-active {
    .PhoneInput {
      .flag-dropdown {
        border: 1px solid $primary !important;
        border-right: 0 !important;
      }
    }
  }

  .PhoneInput {
    .flag-dropdown {
      border-radius: 20px 0 0 20px;
      border: 1px solid $cuColor87 !important;
      background-color: $white;
      border-right: 0 !important;
      width: 65px;
      padding: 0 0 0 28px;

      &.open {
        border-radius: 20px 0 0 20px;
        border: 1px solid $primary !important;
      }

      .selected-flag {
        padding: 0 !important;

        .flag {
          transform: scale(1.1);
        }
      }
    }

    input {
      @extend .form-control;
      margin-left: auto;
      width: calc(100% - 65px);
      font-family: $primaryFont;
      border-left: 0 !important;
      border-radius: 0 20px 20px 0 !important;
      padding: 10px 70px 10px 0 !important;

      &.open {
        border: 1px solid $primary !important;
      }

      @media (max-width: map-get($mediaSizes, "sm")) {
        padding: 10px 35px 10px 0 !important;
      }
    }
  }

  span.required {
    color: $danger;
    @include font2020();
    font-weight: 700;
    position: absolute;
    top: 10px;
    left: 15px;
    z-index: 9;
  }
}

span.main-label {
  @include font1630();
  color: $darkGreen;
  font-weight: 600;
  display: block;
  margin-bottom: 15px;
  text-transform: capitalize;
}

span.error-msg {
  color: $danger;
  @include font1420();
  font-weight: 600;
  display: block;
  margin-top: 5px;
}

.telephone.cu-form-group input:focus ~ .flag-dropdown,
.telephone.cu-form-group input:not(:placeholder-shown) ~ .flag-dropdown,
.telephone.cu-form-group input:hover ~ .flag-dropdown {
  border: 1px solid $primary !important;
  border-right: 0 !important;
}

.floating-label-group {
  position: relative;

  &.with-low-index {
    .floating-label {
      z-index: 0;
    }

    span.required {
      z-index: 0;
    }
  }

  &.telephone.cu-form-group {
    &.show {
      input {
        border-left: 1px solid $primary !important;
      }
    }
  }

  .floating-label {
    text-transform: capitalize;
    color: $cuColor87;
    padding: 0px 5px;
    position: absolute;
    pointer-events: none;
    top: 6px;
    left: 0px;
    right: 0;
    width: fit-content;
    margin: 0 auto;
    opacity: 0;
    text-transform: capitalize;
    font-weight: 600;
    white-space: pre;
    z-index: 9;
    @include trans3();
    @include font1420();
  }

  &.error-feild {
    .password-group {
      input:hover ~ .input-group-text,
      input:focus ~ .input-group-text,
      input:not(:placeholder-shown) ~ .input-group-text {
        border: 1px solid $danger !important;
      }

      input.form-control:not(:placeholder-shown),
      input:hover {
        border: 1px solid $danger !important;
        border-right: 0 !important;
      }

      input.form-control {
        border-right: 0 !important;
        border-color: $danger;
        padding-right: 0 !important;

        &:focus {
          border-right: 0 !important;
        }
      }

      .input-group-text {
        border-color: $danger;
      }
    }
  }

  .password-group {
    input:hover ~ .input-group-text,
    input:focus ~ .input-group-text,
    input:not(:placeholder-shown) ~ .input-group-text {
      border: 1px solid $primary !important;
    }

    input.form-control:not(:placeholder-shown),
    input:hover {
      border: 1px solid $primary !important;
      border-right: 0 !important;
    }

    input.form-control {
      border-right: 0 !important;
      border-color: $cuColor87;
      padding-right: 0 !important;
      border-radius: 20px 0 0 20px !important;

      &:focus {
        border-right: 0 !important;
      }
    }

    .input-group-text {
      background: transparent;
      border-radius: 0 20px 20px 0 !important;
      border-color: $cuColor87;
      @include trans3();

      svg {
        color: $darkGreen;
      }
    }
  }

  input {
    text-align: center;
    border-color: $cuColor87 !important;

    &:disabled {
      background: $white !important;
      cursor: not-allowed;
    }

    &:hover {
      border-color: $primary !important;
    }
  }

  // autofill
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $white inset !important;
    -webkit-text-fill-color: $primary !important;
  }

  // value have o r focus
  input:focus,
  input:not(:placeholder-shown),
  textarea:focus,
  textarea:not(:placeholder-shown) {
    border-color: $primary !important;
  }

  input:disabled {
    color: $primary !important;
    -webkit-text-fill-color: $primary;
    /* Override iOS / Android font color change */
    -webkit-opacity: 1;
    /* Override iOS opacity change affecting text & background color */
    color: $primary;
    /* Override IE font color change */
  }

  input:focus ~ .floating-label,
  input:not(:placeholder-shown) ~ .floating-label,
  textarea:focus ~ .floating-label,
  textarea:not(:placeholder-shown) ~ .floating-label,
  .floating-label.show {
    top: -11px;
    left: 25px;
    right: unset;
    opacity: 1;
    color: $primary;
    // background: $white;
    background: transparent !important;
    height: fit-content;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 3px;
      background-color: white;
      left: 0;
      top: 11px;
      z-index: -1;
    }
  }

  textarea:focus::placeholder,
  input:focus::placeholder {
    opacity: 0;
  }

  input:focus::-webkit-input-placeholder,
  textarea:focus::-webkit-input-placeholder {
    opacity: 0;
  }

  input:focus::-moz-placeholder,
  textarea:focus::-moz-placeholder {
    opacity: 0;
  }

  input:focus:-ms-input-placeholder,
  textarea:focus::-moz-placeholder {
    opacity: 0;
  }

  input:focus:-moz-placeholder,
  textarea:focus:-moz-placeholder {
    opacity: 0;
  }
}

// disable arrows in firefox
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

// common-react-select
.react-select {
  &.min150 {
    .select__control {
      min-width: 80px;
    }
  }

  &.isActive {
    .select__control {
      border: 1px solid $primary;
    }
  }

  &.multi-select {
    .select__control {
      .select__value-container {
        justify-content: center;
        padding: 3px 30px 3px 20px !important;

        .select__multi-value {
          background-color: rgba($primary, 0.5);
          border-radius: 10px;
          margin: 6px 3px;

          .select__multi-value__label {
            color: $white;
            font-size: 12px;
            line-height: 16px;
            font-weight: 700;
          }

          .select__multi-value__remove {
            &:hover {
              background-color: unset;
              color: $white;
            }

            color: $white;
          }
        }
      }
    }
  }

  .select__control {
    border: 1px solid $cuColor87;
    border-radius: 20px;
    min-height: 40px;
    position: relative;
    box-shadow: none;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 15px;
      bottom: 0;
      margin: auto 0;
      width: 10px;
      height: 6px;
      z-index: 9;
      background: url("../images/icon/select-icon.svg") no-repeat;
    }

    &:hover {
      border-color: $primary;
    }

    &.select__control--menu-is-open {
      box-shadow: none;
      border-color: $primary;

      &::before {
        transform: rotate(180deg);
      }
    }

    .select__value-container {
      padding: 2px 0 !important;
    }

    .select__indicators {
      display: none;
    }

    .select__placeholder {
      color: $cuColor87;
      font-weight: 600;
      @include trans3();
      @include font1420();
      text-align: center;
    }

    .select__single-value,
    .select__input-container {
      color: $primary;
      font-weight: 600;
      @include trans3();
      @include font1420();
      text-align: center;
      padding: 5px 25px;

      svg {
        color: white;
        background: $primary;
        border-radius: 50%;
      }
    }
  }
}

.select__menu-portal {
  z-index: 999999 !important;
}

.select__menu {
  border-radius: 10px !important;
  padding: 5px 0;
  z-index: 99 !important;

  .select__menu-list {
    max-height: 150px;
    @include scroll_vertical_primary();

    .select__option {
      @include font1420();
      word-break: break-all;
      color: $darkGreen;
      font-weight: 600;
      text-align: center;
      @include linebreak1();

      svg {
        background: $primary;
        color: white;
        border-radius: 50%;
      }

      &.select__option--is-focused {
        background: rgba($primary, 0.2);
        font-weight: 600;
      }

      &.select__option--is-selected {
        background: $primary;
        color: $white;
        font-weight: 600;

        svg {
          background: white;
          color: $primary;
          border-radius: 50%;
        }
      }
    }
  }
}

// common-modal
.modal-body {
  position: relative;

  .close-icon {
    position: absolute;
    right: 0px;
    top: 0px;
    cursor: pointer;
    z-index: 9;
    @include trans3();

    &.above.diff {
      border: 2px solid $lightGreen;
      background-color: $lightGreen;
      border-radius: 50%;
      top: -15px;
      right: -15px;

      svg {
        height: 30px;
        width: 30px;

        path {
          stroke: $white;
        }
      }
    }

    &.diff {
      svg {
        path {
          stroke: $cuColor87;
        }
      }
    }

    &.bottom-diff {
      top: 10px;
      right: 5px;
    }

    svg {
      path {
        @include trans3();
        stroke: $white;

        @media (max-width: map-get($mediaSizes, "lg")) {
          stroke: $white;
        }
      }
    }

    &:hover {
      transform: rotate(90deg);

      svg {
        path {
          stroke: $lightGreen;

          @media (max-width: map-get($mediaSizes, "lg")) {
            stroke: $lightGreen;
          }
        }
      }
    }

    &.close-green-icon {
      &:hover {
        svg {
          path {
            stroke: $white;
          }
        }
      }
    }

    &.close-white-icon {
      svg path {
        stroke: $white !important;

        @media (max-width: map-get($mediaSizes, "lg")) {
          stroke: $darkGreen !important;
        }
      }

      &:hover {
        svg {
          path {
            stroke: $white !important;

            @media (max-width: map-get($mediaSizes, "lg")) {
              stroke: $darkGreen !important;
            }
          }
        }
      }
    }
  }

  .back-icon {
    position: absolute;
    left: 40px;
    top: 40px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    z-index: 9;
    background-color: $white;
    border-radius: 50%;
    overflow: hidden;
    @include trans3();

    &:hover {
      box-shadow: 0 5px 10px rgba($black, 0.2);
    }
  }
}

@mixin navbar {
  .navbar {
    padding: 30px 0 20px 0;
    background: $white;
    box-shadow: 0 5px 10px rgba($black, 0.05);
    position: relative;
    min-height: 100px;

    @media (max-width: map-get($mediaSizes, "lg")) {
      padding: 28px 0 20px 0;
    }

    @media (max-width: map-get($mediaSizes, "sm")) {
      min-height: 90px;
      padding: 20px 0 20px 0;
    }

    &.header-visibility {
      z-index: 99;
    }

    .navbar-brand {
      margin-left: 0;
      margin-right: 60px;

      @media (max-width: map-get($mediaSizes, "sm")) {
        margin-right: 10px;
        margin-left: 0;
      }

      .main-logo {
        @media (max-width: map-get($mediaSizes, "sm")) {
          width: 100px;
        }

        @media screen and (max-width: "350px") {
          width: 70px;
        }
      }
    }

    .navbar-toggler {
      border: 1px solid $cuColor87;
      border-radius: 10px;
      padding: 4px 4px;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .mobile-list {
      display: none;
      margin-bottom: 0;
      padding: 0 !important;

      span {
        @include font1620();
        color: $offWhite;
        font-weight: 600;
        padding-right: 12px;
        cursor: pointer;
      }

      @media (max-width: map-get($mediaSizes, "lg")) {
        display: inline-flex !important;
        vertical-align: middle;
      }

      li {
        display: inline-block;
        margin-right: 15px;

        @media (max-width: map-get($mediaSizes, "sm")) {
          margin-right: 5px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    ul {
      li {
        margin-right: 40px;

        @media (max-width: map-get($mediaSizes, "md")) {
          margin-right: 0px;
        }

        &:last-child {
          margin-right: 0;
        }

        &.dropdown-language {
          .nav-link {
            padding: 5px !important;
          }
        }

        .nav-link {
          @include font1620();
          color: $darkGreen;
          font-weight: 600;
          padding: 0 !important;
          position: relative;
          @include trans3();

          @media (max-width: map-get($mediaSizes, "lg")) {
            margin-right: 0px;
          }
        }
      }

      &.first {
        margin-left: 0;
        margin-right: auto;

        li {
          .nav-link {
            @include font1620();
            color: $darkGreen;
            font-weight: 600;
            padding: 0 !important;
            position: relative;
            @include trans3();

            .badge {
              width: 7px;
              height: 7px;
              border-radius: 50%;
              background-color: $danger;
              position: absolute;
              top: -11px;
              bottom: 0;
              margin: auto 0;
              right: -7px;
              display: block;
              padding: 0;
            }

            @media (max-width: map-get($mediaSizes, "lg")) {
              padding: 10px !important;
            }

            &::before {
              content: "";
              position: absolute;
              bottom: -7px;
              left: 0;
              width: 100%;
              height: 1px;
              opacity: 0;
              background: $lightGreen;
              @include trans3();

              @media (max-width: map-get($mediaSizes, "lg")) {
                display: none;
              }
            }

            &.active,
            &:hover {
              color: $lightGreen;

              &::before {
                opacity: 1;
              }
            }
          }
        }
      }

      &.second {
        margin-left: auto;
        margin-right: 0;

        span {
          @include font1620();
          color: $offWhite;
          font-weight: 600;
          padding-right: 12px;
          cursor: pointer;
        }

        li {
          margin-right: 20px;

          @media (max-width: map-get($mediaSizes, "lg")) {
            display: none !important;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .icon-text {
      width: 40px;
      height: 40px;
      @include all_center();
      position: relative;

      .badge-notification {
        position: absolute;
        min-width: 25px;
        padding: 0px 2px !important;
        height: 25px;
        background-color: $danger;
        border-radius: 50%;
        right: -2px;
        top: -7px;
        @include all_center();
        color: $white;
        font-size: 12px;
        line-height: 12px;
      }
    }

    .common-dropdown {
      .nav-link {
        width: 50px !important;
        height: 50px !important;
        border-radius: 25px;

        img {
          width: 100%;
          border-radius: 50%;
        }

        .user-img {
          position: relative;

          &::before {
            content: "";
            position: absolute;
            width: 52px !important;
            height: 52px !important;
            border-radius: 25px;
            top: -1px;
            left: -1px;
            z-index: 2;
            border: 2px solid $white;
          }
        }
      }
    }

    .common-dropdown {
      .dropdown-menu {
        padding: 20px 20px;
        min-width: 220px;
        border-radius: 20px;
        border: 0 !important;
        box-shadow: 0px 5px 10px rgba($black, 0.2);
        transform: unset !important;
        top: 140% !important;
        right: 0 !important;

        .dropdown-item {
          padding: 7px 0 8px 0;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          border-bottom: 1px solid $cuColorc8;
          cursor: pointer;
          @include trans3();

          &:focus,
          &:hover,
          &:active {
            background-color: transparent !important;

            span,
            a {
              color: $lightGreen;
            }

            .two {
              color: $lightGreen !important;
            }
          }

          img {
            margin-right: 5px;
            width: 25px !important;
            height: 17px !important;
          }

          .one {
            width: 20px;
            text-align: left;
          }

          .two {
            width: calc(100% - 20px);
            text-align-last: left;
            padding-left: 15px;
            color: $darkGreen !important;
            font-weight: 600;
            @include trans3();
          }

          a,
          span {
            @include font1620();
            color: $darkGreen !important;
            font-weight: 600;
            text-decoration: none;
            cursor: pointer;
            @include trans3();
          }
        }
      }
    }

    .notification-dropdown {
      @media (max-width: map-get($mediaSizes, "cu450")) {
        // display: none !important;
      }

      .dropdown-menu {
        min-width: 360px;
        max-width: 360px;
        right: -155px !important;
        left: unset !important;
        border-radius: 20px;
        box-shadow: 0px 5px 10px rgba($black, 0.1);
        overflow: hidden;
        border: 0 !important;
        padding: 0 !important;

        @media (max-width: map-get($mediaSizes, "lg")) {
          right: -138px !important;
        }

        @media (max-width: map-get($mediaSizes, "sm")) {
          min-width: 320px;
          max-width: 320px;
          // min-width: 290px;
          // max-width: 290px;
          right: -125px !important;
        }

        .inner-notofication {
          overflow-y: auto;
          @include scroll_vertical_primary();
          max-height: calc(100vh - 200px);
          padding-block: 20px;

          @media (max-width: map-get($mediaSizes, "sm")) {
            padding: 15px;
          }

          ul {
            padding: 0;
            margin-bottom: 0;

            li {
              display: block;
              margin-bottom: 12px;
              margin-right: 0 !important;
              padding: 0;
              padding-inline: 20px;
              background-color: $offWhite;
              cursor: pointer;

              &:last-child {
                margin-bottom: 0;
              }

              &.readOnly {
                background-color: transparent;
                .not-body {
                  .badge {
                    display: none;
                  }
                }
              }

              .not-body {
                width: 100%;
                @include all_center();
                position: relative;

                .badge {
                  width: 7px;
                  height: 7px;
                  border-radius: 50%;
                  background-color: $danger;
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  margin: auto 0;
                  right: -5px;
                  display: block;
                  padding: 0;
                }
              }

              .not-image {
                width: 60px;
                height: 60px;
                border-radius: 20px;
                background-color: $cuColorc8;
                background: url("../images/logo/logo.svg") $primary no-repeat;
                background-size: 80% !important;
                background-position: center;

                @media (max-width: map-get($mediaSizes, "sm")) {
                  width: 40px;
                  height: 40px;
                  border-radius: 12px;
                }
              }

              .not-data {
                margin-left: 15px;
                padding-right: 10px;
                width: calc(100% - 75px);
                text-align: left;

                @media (max-width: map-get($mediaSizes, "sm")) {
                  width: calc(100% - 55px);
                }

                span {
                  @include font1220();
                  color: $cuColor87;
                  font-weight: 600;
                  display: block;
                  margin-bottom: 0;
                }

                p {
                  @include linebreak3();
                  @include font1420();
                  color: $darkGreen;
                  font-weight: 600;
                  margin-bottom: 0;
                  word-break: break-word;
                  white-space: break-spaces;
                }
              }
            }
          }
        }
      }
    }
  }

  .user-imgs {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}

// header
header {
  min-height: 100px;
  background: $white;
  z-index: 999 !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  @media (max-width: map-get($mediaSizes, "sm")) {
    min-height: 90px;
  }

  @include navbar();

  &.common-header {
    .contactUS{
      color: $primary !important;
    }
    .dropdown-language {
      .nav-link {
        padding: 5px !important;
      }
    }
  }

  &.static-header {
    background: transparent;
    position: absolute;

    .navbar {
      background: transparent;
      box-shadow: none;
    }

    .dropdown-language {
      .nav-link {
        padding: 5px !important;
        height: 40px;
        border: 1px solid $white;

        .country-flag-data {
          color: $white;
        }

        .selected-language {
          color: $white;
        }
      }
    }
  }

  .header-btn {
    svg {
      margin-left: 5px;
      margin-top: 2px;
    }
  }
}

.home-header {
  @include navbar();
}

// common-design-section
.common-design-section {
  padding: 0;
  margin-top: 100px;

  @media (max-width: map-get($mediaSizes, "sm")) {
    margin-top: 90px;
  }

  .common-design-section-data {
    height: calc(100vh - 100px);
    min-height: fit-content;
    display: flex;
    align-items: center;

    @media (max-width: map-get($mediaSizes, "lg")) {
      height: calc(100vh - 100px);
    }

    @media (max-width: map-get($mediaSizes, "md")) {
      display: block;
      overflow-y: auto;
      @include body_scroll();
    }

    @media (max-width: map-get($mediaSizes, "sm")) {
      height: calc(100vh - 90px);
    }

    &.single-data {
      display: block;

      .right-design-data {
        width: 100%;
        padding: 0;

        @media (max-width: map-get($mediaSizes, "sm")) {
          height: 100%;
        }

        @media (max-width: map-get($mediaSizes, "md")) {
          flex-direction: column;
        }
      }
    }

    &.comming-soon-data {
      display: block;

      .right-design-data {
        width: 100%;
        padding: 100px 0;

        @media (max-width: map-get($mediaSizes, "sm")) {
          height: calc(100vh - 80px);
          padding: 40px 0;
        }

        @media (max-width: map-get($mediaSizes, "md")) {
          flex-direction: column;
          padding: 60px 0;
        }
      }
    }

    .left-design-data {
      position: relative;
      background-color: $primary;
      height: 100%;
      width: 480px;
      @include all_center();
      margin-right: auto;
      max-height: calc(100vh - 100px);

      @media (max-width: map-get($mediaSizes, "xl")) {
        width: 400px;
      }

      @media (max-width: map-get($mediaSizes, "lg")) {
        width: 350px;
      }

      @media (max-width: map-get($mediaSizes, "md")) {
        width: 100%;
        height: auto;
        padding: 50px 0;
      }

      .box-data {
        padding: 25px 40px;
        width: 100%;
        background-color: $offWhite;
        box-shadow: 0 5px 10px rgba($black, 0.1);
        height: fit-content;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto 0;

        @media (max-width: map-get($mediaSizes, "sm")) {
          padding: 25px 15px;
        }

        .data {
          max-width: 320px;
          margin: 0 auto;

          h2 {
            @include font4050();
            font-weight: 700;
            color: $darkGreen;
            margin-bottom: 10px;
            text-transform: uppercase;
            letter-spacing: -0.2px;

            @media (max-width: map-get($mediaSizes, "xl")) {
              @include font3040();
            }

            @media (max-width: map-get($mediaSizes, "lg")) {
              @include font3040();
            }

            @media (max-width: map-get($mediaSizes, "sm")) {
              @include font2540();
            }
          }

          p {
            @include font1420();
            font-weight: 600;
            color: $darkGreen;
            margin-bottom: 0;
          }
        }
      }
    }

    .hide-in-mobile {
      @media (max-width: map-get($mediaSizes, "sm")) {
        img {
          display: none;
        }
      }
    }

    .right-design-data {
      background-color: $white;
      height: 100%;
      width: calc(100% - 480px);
      height: fit-content;
      margin: auto;
      max-height: calc(100vh - 100px);
      overflow-y: auto;
      overflow-x: hidden;
      @include body_scroll();

      // scrollbar-gutter: stable;
      @media (max-width: map-get($mediaSizes, "xl")) {
        width: calc(100% - 400px);
      }

      @media (max-width: map-get($mediaSizes, "lg")) {
        width: calc(100% - 350px);
      }

      // @media (max-width: map-get($mediaSizes, "md")) {
      //   width: 100%;
      //   overflow-y: unset !important;
      //   max-height: unset !important;
      // }

      .spacing-right-data {
        padding: 50px 40px;
        min-height: calc(100vh - 150px);

        &.centers {
          @include all_center();
        }

        @media (max-width: map-get($mediaSizes, "sm")) {
          padding: 40px 15px;
        }
      }

      .spacing-min-height {
        min-height: calc(100vh - 150px);

        @media (max-width: map-get($mediaSizes, "lg")) {
          min-height: calc(100vh - 150px);
        }

        @media (max-width: map-get($mediaSizes, "sm")) {
          min-height: calc(100vh - 140px);
        }
      }

      .box {
        max-width: 400px;
        margin: 0 auto;

        @media (max-width: map-get($mediaSizes, "md")) {
          padding-bottom: 20px;
        }

        .logo-div {
          margin-bottom: 60px;
        }
      }
    }
  }
}

// Toastify
.Toastify {
  .Toastify__toast-container {
    padding: 0 !important;
    top: 20px;
    right: 15px;
    margin-left: auto;
    z-index: 9999999 !important;

    @media (max-width: map-get($mediaSizes, "md")) {
      right: 10px;
      max-width: 95%;
    }

    .Toastify__toast {
      margin: 10px 4px 0px auto !important;
      border-radius: 6px;
      padding: 0;
      box-shadow: none !important;
      min-height: 45px;
      background-color: $white !important;

      &.Toastify__toast--success {
        box-shadow: 0 6px 15px -7px rgba($lightGreen, 0.4);

        .Toastify__toast-body {
          background-color: rgba($lightGreen, 0.12) !important;

          div {
            color: $lightGreen;
          }
        }

        .Toastify__close-button {
          background-color: rgba($lightGreen, 0.12) !important;

          svg {
            fill: $lightGreen;
          }
        }
      }

      &.Toastify__toast--error {
        box-shadow: 0 6px 15px -7px rgba($danger, 0.4);

        .Toastify__toast-body {
          background-color: rgba($danger, 0.12) !important;

          div {
            color: $danger;
          }
        }

        .Toastify__close-button {
          background-color: rgba($danger, 0.12) !important;

          svg {
            fill: $danger;
          }
        }
      }

      .Toastify__toast-body {
        padding: 10px 15px;
        margin: 0;

        .Toastify__toast-icon {
          display: none;
        }

        div {
          @include font1420();
          font-weight: 600;
          font-family: $primaryFont;
        }
      }

      .Toastify__progress-bar {
        opacity: 0;
        visibility: hidden;
      }

      .Toastify__close-button {
        align-self: unset;
        margin-left: 0;
        padding: 0 10px 0 0;
        opacity: 1 !important;

        svg {
          width: 14px;
          height: 14px;
          margin-top: -3px;
        }
      }
    }
  }
}

// SLIDER SECTION
.slider-section {
  padding-bottom: 100px;

  @media (max-width: map-get($mediaSizes, "xl")) {
    padding-bottom: 50px;
  }

  .slick-track {
    margin-left: 0 !important;
  }

  h5 {
    @include font2037();
    color: $cuColor87;
    margin-bottom: 20px;
  }

  .slick-arrow {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    @include all_center();
    background-color: $offWhite;
    z-index: 99;
    @include trans3();

    &:focus {
      background-color: $offWhite;
    }

    &:hover {
      background-color: $primary;

      &::before {
        filter: brightness(0) invert(1);
      }
    }

    &::before {
      content: "" !important;
      height: 8px;
      width: 14px;
      background: url("../../assets/images/icon/select-icon.svg") no-repeat;
      background-size: 100%;
      opacity: 1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      @include trans3();
    }

    &.slick-disabled {
      display: none !important;
    }

    &.slick-prev {
      left: 0;

      &::before {
        transform: rotate(90deg);
      }
    }

    &.slick-next {
      right: 0;

      &::before {
        content: "";
        transform: rotate(-90deg);
      }
    }
  }

  .slider-card {
    text-align: center;

    .slider-card-img {
      border-radius: 50%;
      overflow: hidden;
      width: 180px;
      height: 180px;
      margin: 0 auto 15px;
      border: 1px solid $warning !important;
      @include bgCover();
    }

    .slider-card-body {
      h3 {
        @include font1620();
        font-weight: 600;
        color: $darkGreen;
        margin-bottom: 0;
        text-transform: capitalize;
      }

      .bottom-data {
        width: 100%;
        @include all_center();

        .star-data {
          width: calc(100% - 30px);
          max-width: 78px;

          .user-select-none {
            pointer-events: none;
            user-select: none;
            margin-right: 2.5px;

            span {
              margin-right: 2.5px;
            }
          }
        }

        .comment-data {
          color: $cuColor87;
          font-weight: 700;
          @include font1220();
        }
      }
    }
  }
}

.alert-section {
  .alert.alert-dismissible {
    margin-top: 20px;
    padding: 19px 40px;
    border-radius: 20px !important;
    @include font1420();
    font-weight: 600;
    color: $darkGreen;
    text-align: center;
    direction: ltr;
    margin-bottom: 0;

    span,
    a {
      text-decoration: none;
      font-weight: 700;
      color: $darkGreen;
    }

    a {
      cursor: pointer;
    }

    &.alert-info {
      background-color: $offWhite;
      border: 1px solid $offWhite;
    }

    .btn-close {
      background-color: $white;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      padding: 0;
      top: 5px;
      right: 5px;
      left: unset;
      @include all_center();
      background-size: 10px;
      opacity: 1;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}

.crop-modal {
  &.modal-dialog {
    width: calc(100% - 30px);
    margin-left: auto;
    margin-right: auto;

    @media (max-width: map-get($mediaSizes, "mid")) {
      max-width: 600px;
    }

    @media (min-width: map-get($mediaSizes, "sm")) {
      max-width: 600px;
    }
  }

  .modal-content {
    border: 0;
    border-radius: 5px !important;
    box-shadow: 0px 5px 10px rgba($black, 0.1);
  }

  .modal-body {
    padding: 30px;

    @media (max-width: map-get($mediaSizes, "sm")) {
      padding: 20px;
    }

    .crop-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      h4 {
        @include font2528();
        font-weight: 600;
        color: $darkGreen;
        margin-bottom: 0px;
        width: calc(100% - 30px);

        @media (max-width: map-get($mediaSizes, "sm")) {
          @include font2020();
        }
      }

      span {
        width: 24px;
        display: block;

        svg {
          color: $cuColor87;
          @include trans3();
          cursor: pointer;

          &:hover {
            transform: rotate(90deg);
            color: $lightGreen;
          }
        }
      }
    }

    .ReactCrop {
      width: 100%;

      .ReactCrop__image {
        width: 100%;
      }
    }

    .crop-modal-btn {
      margin-top: 15px;
      gap: 15px;
      @include all-center();

      button {
        min-width: 150px;

        @media (max-width: map-get($mediaSizes, "sm")) {
          min-width: 100px;
        }
      }
    }
  }
}

.map-box {
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 40px;

  &.bigger {
    border-radius: 15px;
    overflow: hidden;
  }
}

// no-data-section
.no-data-section {
  padding: 20px;
  flex-direction: column;
  border-radius: 20px;
  margin: 5px 0 0;
  box-shadow: 0px 0px 10px rgba($lightGreen, 0.2);
  @include all_center();

  &.no-shadow {
    padding: 0;
    box-shadow: none;
  }

  p {
    @include font1630();
    color: $darkGreen;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 10px;
  }
}

.floating-label-group {
  .autocomplete-dropdown-container {
    position: absolute;
    top: 45px;
    left: 0;
    width: 300px;
    background: $white;
    box-shadow: 0px 5px 10px rgba($black, 0.2);
    border-radius: 8px;
    z-index: 99;
    border-radius: 10px;
    overflow-y: auto;
    max-height: 200px;
    color: $darkGreen !important;
    @include trans3();
    @include scroll_vertical_main_hover();

    .suggestion-item,
    .suggestion-item--active {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px !important;
      line-height: 20px !important;
      color: $darkGreen !important;
      font-size: 12px !important;
      line-height: 20px !important;

      &:first-child {
        padding-top: 15px;
      }

      span {
        color: $darkGreen !important;
        font-size: 12px !important;
        line-height: 20px !important;
        font-weight: 600;
        transition: 0.3s ease-in-out;

        &:hover {
          font-size: 12px !important;
          line-height: 20px !important;
          color: $lightGreen !important;
        }
      }
    }
  }
}

.shadow-card {
  border-radius: 20px;
  padding: 15px;
  box-shadow: 0px 5px 10px rgba($black, 0.1);
}

.flatpickr-calendar {
  .flatpickr-months .flatpickr-month {
    background: $lightGreen;
  }

  .flatpickr-current-month .flatpickr-monthDropdown-months {
    background: $lightGreen;
  }

  .flatpickr-weekdays {
    background: $lightGreen;
  }

  span.flatpickr-weekday {
    background: $lightGreen;
    color: $white;
    font-weight: 400;
  }
}

.flatpickr-input {
  outline: none !important;
  box-shadow: none !important;
  margin-bottom: 0px !important;

  &.active {
    border-color: $lightGreen !important;
    outline: none !important;
    box-shadow: none !important;
  }
}

.date-input-value {
  border-color: $darkGreen !important;
}

.flatpickr-day.selected,
.flatpickr-day.selected.startRange,
.flatpickr-day.startRange,
.flatpickr-day.endRange {
  background: $lightGreen !important;
}

.galary-data {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;

  @media (max-width: map-get($mediaSizes, "lg")) {
    gap: 20px;
  }

  @media (max-width: map-get($mediaSizes, "md")) {
    grid-template-columns: repeat(1, 1fr);
  }

  .galary-card {
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0px 5px 10px rgba($black, 0.1);
    background-color: $white;
    // cursor: pointer;
    text-decoration: none;

    .card-img {
      position: relative;
      border-radius: 20px;
      height: 203px;
      background-color: $cuColorc8;
      @include bgCover();
      overflow: hidden;

      video {
        transform: scale(1.2);
      }

      .video-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        svg {
          width: 34px;
          height: 34px;
          color: $primary;
          background: $white;
          border-radius: 50%;
        }
      }
    }

    .card-body {
      padding: 5px 15px;
      text-align: center;

      span {
        cursor: pointer;
        @include font1426();
        font-weight: 600;
        color: $darkGreen;
        display: block !important;
        @include linebreak1();
      }
    }
  }
}

.common-modal {
  &.modal-dialog {
    width: calc(100% - 30px);
    margin-left: auto;
    margin-right: auto;

    @media (max-width: map-get($mediaSizes, "mid")) {
      max-width: 450px;
    }

    @media (min-width: map-get($mediaSizes, "sm")) {
      max-width: 450px;
    }
  }

  .modal-content {
    border: 0;
    border-radius: 20px !important;
    box-shadow: 0px 5px 10px rgba($black, 0.1);

    &.success-shadow {
      box-shadow: 0px 0px 10px rgba($lightGreen, 0.3);
    }
  }

  .modal-body {
    padding: 25px 25px;

    h3.head-data {
      @include font2230();
      font-weight: 600;
      color: $darkGreen;
      margin-bottom: 30px;

      @media (max-width: map-get($mediaSizes, "lg")) {
        @include font1626();
      }
    }

    p {
      @include font1626();
      font-weight: 600;
      color: $cuColor87;
      margin-bottom: 30px;
    }
  }
}

.form-switch {
  .form-check-input {
    width: 45px !important;
    height: 26px;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  &.form-check-primary {
    margin-top: 1px;

    .form-check-input {
      background-color: $white;
      border-color: $lightGreen;
      background-image: unset !important;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        left: 3px;
        height: 18px;
        width: 18px;
        background: rgba($lightGreen, 1);
        border-radius: 50%;
        transition: 0.3s ease-in-out;
      }

      &:checked {
        background-color: $lightGreen;
        border-color: $lightGreen;

        &::before {
          left: 22px;
          background: rgba($white, 1);
        }
      }
    }
  }
}

input {
  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }
}

.add-branch-btn {
  .btn {
    position: relative;
    padding-left: 40px;
    padding-right: 40px;

    .plus-icon {
      position: absolute;
      top: 0;
      left: 10px;
      bottom: 0;
      margin: auto 0;
      background-color: $primary;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      color: $white;
      @include all_center();
    }
  }
}

::selection {
  color: white !important;
  background-color: $primary !important;
}

::-moz-selection {
  color: white !important;
  background-color: $primary !important;
}

.comming-box {
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
  padding: 0 15px;

  h1 {
    color: $darkGreen;
    @include font4050();
    font-weight: 700;
    margin-bottom: 20px;
    text-transform: uppercase;

    @media (max-width: map-get($mediaSizes, "lg")) {
      @include font3040();
    }
  }

  p {
    margin: 30px 0;
    @include font2037();
    color: $darkGreen;
    font-weight: 600;

    @media (max-width: map-get($mediaSizes, "lg")) {
      @include font1620();
    }
  }

  button {
    min-height: 50px;
    height: 50px;
    border-radius: 30px;
    padding: 5px 30px !important;

    svg {
      margin-top: -2px;
      margin-right: 8px;
    }
  }
}

.static-data {
  .right-design-data {
    // padding: 50px 15px;
    width: 100% !important;

    .static-box {
      .inner-title-data {
        position: relative;

        .arrowDiv {
          position: absolute;
          left: -10px;
          top: 15px;

          @media (max-width: map-get($mediaSizes, "sm")) {
            top: 12px;
          }

          svg {
            width: 30px;
          }
        }
      }

      h2 {
        text-align: center;
        @include font4050();
        font-weight: 700;
        color: $darkGreen;
        margin: 0 auto 35px auto;
        padding: 0px 20px;

        @media (max-width: map-get($mediaSizes, "lg")) {
          @include font3040();
          margin: 0 auto 30px auto;
        }
      }

      .box-data {
        padding-bottom: 20px;

        h4 {
          @include font2528();
          font-weight: 700;
          color: $darkGreen;
          margin-bottom: 25px;

          @media (max-width: map-get($mediaSizes, "lg")) {
            @include font2230();
          }
        }

        ol {
          padding: 0;
          margin-bottom: 0;
          margin-top: 20px;
          // counter-reset: alphabeticList;
          display: block;

          li {
            @include font1830();
            color: $darkGreen;
            font-weight: 500;
            position: relative;
            // padding-left: 20px;
            margin-left: 20px;
            margin-bottom: 20px;
          }
        }

        ul {
          padding: 0;
          margin-bottom: 0;

          li {
            @include font1830();
            color: $darkGreen;
            font-weight: 500;
            position: relative;
            padding-left: 20px;
            margin-bottom: 20px;
            display: block;

            @media (max-width: map-get($mediaSizes, "lg")) {
              @include font1620();
            }

            &::before {
              content: "";
              position: absolute;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: $darkGreen;
              top: 12px;
              left: 0;
              @include font1830();

              @media (max-width: map-get($mediaSizes, "lg")) {
                @include font1620();
              }
            }

            ol {
              padding: 0;
              margin-bottom: 0;
              margin-top: 20px;
              counter-reset: alphabeticList;
              display: block;

             

              li {
                counter-increment: alphabeticList;
                display: block;

                &::before {
                  content: counter(alphabeticList, upper-alpha);
                  position: absolute;
                  text-transform: lowercase;
                  background: transparent;
                  top: 0;
                  width: unset;
                  height: unset;
                  left: 0;
                  font-weight: 700;
                }

                &::after {
                  content: ".";
                  position: absolute;
                  left: 10px;
                  top: 0;
                  font-weight: 700;
                }
              }
            }
          }
        }
        ol{
          @include font1830();
            color: $darkGreen;
            font-weight: 500;
            position: relative;
            padding-left: 20px;
            margin-bottom: 20px;
            display: block;
        }
      }

      p,
      a {
        @include font1830();
        color: $darkGreen;
        font-weight: 500;
        margin-bottom: 20px;

        @media (max-width: map-get($mediaSizes, "lg")) {
          @include font1620();
        }
      }

      a {
        text-decoration: underline;
        transition: 0.3s ease-in-out;
        &:hover {
          color: $primary;
        }
      }
    }
  }
}

.dropdown-language {
  .nav-link {
    padding: 5px !important;
    height: 40px;
    border: 1px solid $primary;
    border-radius: 20px;
    @include all_center();

    .country-flag-data {
      width: 25px !important;
      height: 25px !important;
      overflow: hidden;
      border-radius: 50%;
      display: inline-block;
      margin-right: 5px;
      color: $lightGreen;

      img,
      svg {
        width: 100%;
        height: 100%;
      }
    }

    .selected-language {
      @include font1620();
      color: $lightGreen;
      font-weight: 600;
      padding-right: 2px;
      min-width: 20px;
    }
  }

  .dropdown-menu.with-lang {
    padding: 15px 10px;
    min-width: 160px;
    border-radius: 20px;
    border: 0 !important;
    box-shadow: 0px 5px 10px rgba($black, 0.2);
    transform: unset !important;
    top: 110% !important;
    right: 0 !important;
    left: unset !important;

    .dropdown-item {
      padding: 8px 10px;
      display: flex;
      align-items: center;
      width: 100%;
      @include trans3();

      &:focus,
      &:hover,
      &:active {
        background-color: transparent !important;

        span {
          color: $darkGreen;
        }
      }

      img {
        margin-right: 5px;
        width: 22px !important;
        height: 22px !important;
      }

      span {
        @include font1620();
        color: $black;
        font-weight: 600;
        @include trans3();
      }
    }
  }
}

.new-tab {
  height: 100vh;
  width: 100vw;
  @include all_center();

  .new-tab-box {
    width: calc(100% - 30px);
    max-width: 500px;
    margin: 0 auto;
    background-color: $white;
    box-shadow: 0 5px 10px rgba($black, 0.2);
    padding: 30px 20px;
    border-radius: 0px;

    p {
      color: $primary;
      @include font2037();
      margin-bottom: 0;
      text-align: center;
      font-weight: 600;
    }

    .btn {
      min-height: 45px;
      border-radius: 22.5px;
      margin-top: 30px;
    }
  }
}

.react-tel-input .country-list {
  left: 0;
  width: 400px !important;
  @include body_scroll();

  @media (max-width: map-get($mediaSizes, "sm")) {
    width: 250px !important;
  }
}

.primary-tip {
  .tooltip {
    opacity: 1 !important;

    &[data-popper-placement^="bottom"] .tooltip-arrow::before,
    .bs-tooltip-bottom .tooltip-arrow::before {
      border-bottom-color: $primary;
    }

    .tooltip-inner {
      padding: 8px 15px;
      font-weight: 700;
      border-radius: 20px;
      background-color: $primary;
    }
  }
}

footer {
  padding: 15px 0;
  width: 100%;
  background-color: $white;
  box-shadow: 0px 5px 10px rgba($black, 0.3);

  p {
    margin-bottom: 0;
    @include font1620();
    font-weight: 600;
    color: $darkGreen;
    text-align: center;
  }
}

.overlay-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9;
}

.text-wrap-balance {
  text-wrap: balance;
}

.select-list-icon {
  background: $primary;
  color: white;
  border-radius: 50%;
}

.checkbox-square {
  display: flex;

  input {
    &:checked+label {
      &:before {
        background-color: $primary;
        border: 1px solid $primary;
      }
    }
  }

  label {
    position: relative;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    color: $cuColor87;
    margin-bottom: 0;
    padding-left: 30px;
    cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      min-width: 20px;
      min-height: 20px;
      left: 0;
      top: 0;
      background-color: $white;
      border: 1px solid $cuColor87;
    }

    &:after {
      content: "";
      position: absolute;
      left: 7px;
      top: 2px;
      width: 6px;
      height: 14px;
      border: 1px solid $white;
      border-width: 0 2px 2px 0 !important;
      transform: rotate(45deg);
    }
  }
}

.br12 {
  border-radius: 12px !important;
}

.border-green {
  border: 1px solid $primary !important;
}

.progress-bar-icons{
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 5px 10px $gray;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;

  @media all and (max-width: 650px) {
    flex-direction: "column";
    align-items: "center";
    display: "block";
  }
}

.progress-bar-line {
  border-top: 2px dashed #FFD700;
  flex-grow: 1;
  height: 36px;
}

.progress-bar-item {
  width: 37px;
  text-align: center;
}

p{
  font-size: 12px;
}

.under-progress {
  padding-top: 15px;
}
.on-way{
  padding-top: 15px;
}
.waiting-proposal{
  padding-top: 15px;
}

.markAllAsRead{
  font-size: 12px;
  margin-right: 8px;
  color: #006464;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;

  &:hover{
    color:$primary;
  }
}
.text-editor-wrapper {
  border: 1px solid $primary;
  border-radius: 10px;
  padding: 5px;
}
.demo-editor {
  background-color: white;
  padding: 1rem;
}

.public-DraftStyleDefault-ltr {
  direction: ltr;
  text-align: left;
}