@import "../../assets/scss/variable";

.full-auth-page {
  height: 100vh;
  display: flex;
  align-items: center;

  @media (max-width: map-get($mediaSizes, "md")) {
    flex-direction: column;
  }

  .left-auth-data {
    background-color: $primary;
    height: 100%;
    width: 480px;
    @include all_center();
    margin-right: auto;
    position: relative;

    @media (max-width: map-get($mediaSizes, "xl")) {
      width: 400px;
    }

    @media (max-width: map-get($mediaSizes, "lg")) {
      width: 350px;
    }

    @media (max-width: map-get($mediaSizes, "md")) {
      width: 100%;
      height: auto;
      padding: 50px 0;
    }

    .box-data {
      padding: 25px 40px;
      width: 100%;
      background-color: $offWhite;
      box-shadow: 0 5px 10px rgba($black, 0.1);
      height: fit-content;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      margin: auto 0;

      @media (max-width: map-get($mediaSizes, "sm")) {
        padding: 25px 15px;
      }

      .data {
        max-width: 320px;
        margin: 0 auto;

        h2 {
          @include font4050();
          font-weight: 700;
          color: $darkGreen;
          margin-bottom: 10px;
          text-transform: uppercase;

          @media (max-width: map-get($mediaSizes, "xl")) {
            @include font3040();
          }

          @media (max-width: map-get($mediaSizes, "lg")) {
            @include font3040();
          }
        }

        p {
          @include font1420();
          font-weight: 600;
          color: $darkGreen;
          margin-bottom: 0;
        }
      }
    }
  }

  .right-auth-data {
    background-color: $white;
    height: 100%;
    width: calc(100% - 480px);
    padding: 40px 40px;
    height: fit-content;
    margin: auto;
    overflow-y: auto;
    @include body_scroll();

    @media (max-width: map-get($mediaSizes, "xl")) {
      width: calc(100% - 400px);
    }

    @media (max-width: map-get($mediaSizes, "lg")) {
      width: calc(100% - 350px);
    }

    @media (max-width: map-get($mediaSizes, "md")) {
      width: 100%;
      overflow: unset;
    }

    @media (max-width: map-get($mediaSizes, "sm")) {
      padding: 40px 15px;
    }

    .data {
      max-width: 400px;
      margin: auto;

      .text-data {
        padding: 60px 0 40px;

        h1 {
          @include font4555();
          font-weight: 700;
          color: $primary;
          margin-bottom: 0;
        }
      }

      p {
        @include font1620();
        font-weight: 600;
        color: $darkGreen;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.login-modal {
  &.modal-dialog {
    width: calc(100% - 30px);
    margin-left: auto;
    margin-right: auto;

    @media (max-width: map-get($mediaSizes, "mid")) {
      max-width: 500px;
    }

    @media (max-width: map-get($mediaSizes, "sm")) {
      max-width: 500px;
    }

    @media all and (min-width: 991px) {
      max-width: 1240px;
    }
  }

  .modal-content {
    border-radius: 0;
    border: 0 !important;
  }

  .modal-body {
    padding: 0;

    .login-data {
      display: flex;
      flex-direction: row-reverse;
      width: 100%;
      height: 90vh;

      @media (max-width: map-get($mediaSizes, "lg")) {
        flex-direction: column;
        height: auto;
      }

      .left-login-data {
        background-color: $primary;
        width: calc(100% - 560px);
        padding: 30px 70px 30px 70px;
        height: 100%;
        @include all_center();

        @media (max-width: map-get($mediaSizes, "xl")) {
          width: calc(100% - 500px);
        }

        @media (max-width: map-get($mediaSizes, "lg")) {
          width: 100%;
          padding: 40px 50px;
          height: auto;
          display: none;
        }

        @media (max-width: map-get($mediaSizes, "sm")) {
          padding: 30px 50px;
        }

        img {
          @media (max-width: map-get($mediaSizes, "xl")) {
            height: 400px;
          }

          @media (max-width: map-get($mediaSizes, "lg")) {
            height: 300px !important;
          }

          @media (max-width: map-get($mediaSizes, "md")) {
            height: auto !important;
            max-height: 300px;
          }
        }
      }

      .right-login-data {
        position: relative;
        width: 560px;
        background: $white;
        padding: 60px 30px;
        max-height: 90vh;
        overflow-y: auto;
        margin: auto 0;
        @include body_scroll();

        @media (max-width: map-get($mediaSizes, "xl")) {
          width: 500px;
          padding: 50px 30px;
        }

        @media (max-width: map-get($mediaSizes, "lg")) {
          width: 100%;
          padding: 50px 30px;
        }

        @media (max-width: map-get($mediaSizes, "sm")) {
          padding: 40px 20px;
        }

        .inner-data {
          width: 100%;
          max-width: 400px;
          margin: 0 auto;
          text-align: center;

          .common-lang-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;

            .lang-ul {
              padding: 0;
              margin: 0;
              display: block;

              li {
                display: block;
              }
            }
          }
        }

        .form-data {
          padding: 60px 0;
          text-align: left;

          .full-check {
            display: flex;
            justify-content: center;

            input {
              &:checked+label {
                &:before {
                  background-color: $primary;
                }
              }
            }

            label {
              position: relative;
              font-size: 14px;
              line-height: 26px;
              font-weight: 700;
              color: $darkGreen;
              margin-bottom: 0;
              padding-left: 40px;
              cursor: pointer;

              a {
                color: $lightGreen;
                font-weight: 600;
                text-decoration: underline;
              }

              &:before {
                content: "";
                position: absolute;
                min-width: 30px;
                min-height: 30px;
                left: 0;
                top: 0;
                border-radius: 50%;
                background-color: $white;
                border: 1px solid $primary;
              }

              &:after {
                content: "";
                position: absolute;
                left: 12px;
                top: 6px;
                width: 6px;
                height: 14px;
                border: solid $white;
                border-width: 0 2px 2px 0 !important;
                transform: rotate(45deg);
              }
            }
          }
        }

        .floating-label-group {
          input {
            &:disabled {
              background-color: $cuColorF2 !important;
            }
          }
        }

        p.note {
          @include font1220();
          color: $cuColor87;
          font-weight: 600;
          margin-bottom: 20px;

          &.bottom-note {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

.attach-btns {
  border: 1px solid $offWhite;
  background-color: $offWhite;
  color: $darkGreen;
  height: 40px;
  border-radius: 20px;
  padding: 5px 17px 5px 11px;
  @include all_center();
  width: fit-content;
  min-width: 95px;
  cursor: pointer;
  @include trans3();
  @include font1420();
  font-weight: 600;

  &:hover {
    border: 1px solid $lightGreen;
  }

  svg {
    margin-right: 10px;
    width: 18px;
    height: 20px;
  }
}

.listing-attach {
  padding-left: 0;
  margin-bottom: 0;
  max-height: 250px;
  overflow-y: auto;
  width: calc(100% + 10px);
  padding-right: 10px;
  @include scroll_vertical_primary();

  li {
    margin-bottom: 20px;
    display: block;

    .list-data {
      width: 100%;
      display: flex;
      align-items: center;
      background-color: $offWhite;
      padding: 5px 10px;
      border-radius: 20px;
      column-gap: 10px;

      .one {
        min-width: 30px;
        min-height: 30px;
        border-radius: 50%;
        background-color: $danger;
        color: $white;
        cursor: pointer;
        @include all_center();

        svg {
          @include trans3();
        }

        &:hover {
          svg {
            transform: rotate(-90deg);
          }
        }
      }

      .two {
        width: calc(100% - 30px);
        color: $cuColor87;
        @include font1420();
        font-weight: 600;
      }
    }
  }
}

// RTL CSS
[dir="rtl"] {
  .login-modal {
    &.modal-dialog {
      .modal-body {
        .login-data {
          .right-login-data {
            .form-data {
              text-align: right;

              .full-check {
                text-align: end;

                label {
                  text-align: right;
                  padding-right: 40px;
                  padding-left: 0;

                  &:before {
                    left: unset;
                    right: 0;
                  }

                  &:after {
                    right: 12px;
                    left: unset;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .attach-btns {
    padding: 5px 11px 5px 17px;

    svg {
      margin-right: 0;
      margin-left: 10px;
    }
  }
}