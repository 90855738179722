@import "../../assets/scss/variable";

// PROFILE SECTION
.profile-section {
  .floating-label-group {
    input {
      &:disabled {
        background-color: $cuColorF2 !important;
      }
    }
  }
  .upload-div {
    text-align: center;
    margin: 0px 0 40px;
    label {
      width: 180px;
      height: 180px;
      border-radius: 50%;
      margin: 0 auto;
      background-color: $offWhite !important;
      @include all_center();
      @include font1620();
      font-weight: 600;
      color: $darkGreen;
      cursor: pointer;
      &.has {
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: cover !important;
        border: 1px solid $cuColorc8 !important;
      }
    }
    .main-label {
      margin-top: 15px;
      font-weight: 700;
    }
  }
  .allow-box{
    padding: 20px 60px;
    border-radius: 20px;
    background-color: $offWhite;
    margin-bottom: 20px;
    text-align: center;
    p{
      @include font1420();
      color: $cuColor87;
      font-weight: 600;
      max-width: 280px;
      margin: 0 auto 15px auto;
    }
  }
  .branch-header{
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 15px;
    .close-branch {
      min-width: 30px;
      min-height: 30px;
      border-radius: 50%;
      background-color: $danger;
      color: $white;
      cursor: pointer;
      @include all_center();
      svg{
        @include trans3();
      }
      &:hover{
        svg{
          transform: rotate(-90deg);
        }
      }
    }
    .two {
      width: calc(100% - 30px);
      color: $cuColor87;
      @include font1420();
      font-weight: 600;
    }
  }
}

// RTL CSS
[dir="rtl"] {
}