@import "../../assets/scss/variable";

// BUNDLE SECTION
.bundle-section {
  &.common-design-section .common-design-section-data .right-design-data {
    @media (max-width: map-get($mediaSizes, "sm")) {
      padding: 40px 5px 40px 15px !important;
    }
  }
  ul.package-listing {
    padding: 25px 0 0 0;
    li {
      display: block;
      margin-bottom: 40px;
      &:nth-child(1) {
        .package-data {
          .card-data {
            .inner-box {
              background-color: $lightGreen;
              color: $white;
            }
          }
        }
      }
      &:nth-child(2) {
        .package-data {
          .card-data {
            .inner-box {
              background-color: $gray;
            }
          }
        }
      }
      &:nth-child(3) {
        .package-data {
          .card-data {
            .inner-box {
              background-color: $warning;
            }
          }
        }
      }
      .package-data {
        display: flex;
        align-items: center;
        .radio-data {
          width: 40px;
          height: 40px;
          @media (max-width: map-get($mediaSizes, "md")) {
            width: 30px;
            height: 30px;
          }
          label {
            position: relative;
            width: 40px;
            height: 40px;
            cursor: pointer;
            @media (max-width: map-get($mediaSizes, "md")) {
              width: 30px;
              height: 30px;
            }
            &:before {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              border-radius: 50%;
              border: 1px solid $darkGreen;
            }
            &:after {
              content: "";
              position: absolute;
              width: 24px;
              height: 24px;
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
              margin: auto;
              border-radius: 50%;
              background-color: transparent;
              @media (max-width: map-get($mediaSizes, "md")) {
                width: 20px;
                height: 20px;
              }
            }
          }
          input {
            &:checked + label {
              &:after {
                background-color: $lightGreen;
              }
            }
          }
        }
        .card-data {
          width: calc(100% - 40px);
          padding-left: 20px;
          @media (max-width: map-get($mediaSizes, "lg")) {
            padding-left: 15px;
          }
          @media (max-width: map-get($mediaSizes, "md")) {
            width: calc(100% - 30px);
          }
          .inner-box {
            padding: 10px 20px;
            border-radius: 20px;
            border: 1px solid $cuColorc8;
            width: 100%;
            min-height: 150px;
            position: relative;
            overflow: hidden;
            color: $darkGreen;
            cursor: pointer;
            &::before {
              content: "";
              width: 140px;
              height: 140px;
              border-radius: 50%;
              background-color: $white;
              right: -20px;
              bottom: -20px;
              z-index: 0;
              position: absolute;
              @media (max-width: map-get($mediaSizes, "lg")) {
                width: 120px;
                height: 120px;
              }
              @media (max-width: map-get($mediaSizes, "sm")) {
                width: 80px;
                height: 80px;
              }
            }
            p {
              @include font1630();
              margin-bottom: 0;
              color: inherit;
              font-weight: 700;
            }
            ul {
              padding: 0;
              margin: 0;
              width: 150px;
              li {
                display: block;
                @include font1420();
                color: inherit;
                font-weight: 600;
                padding: 0;
                margin: 0;
                padding-left: 10px;
                position: relative;
                &::before {
                  content: "-";
                  position: absolute;
                  top: 0;
                  left: 0;
                  @include font1420();
                  color: inherit;
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
    }
  }
}

// RTL CSS
[dir="rtl"] {
}
