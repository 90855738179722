@import "./_variable.scss";

$shimmerColor1: #f6f6f6;
$shimmerColor2: #f0f0f0;
$shimmerTableHead: #f2f4f6;

.shimmer-thumbnail {
  &.h24 {
    height: 24px !important;
  }
}
.custom-table {
  width: 100%;
  .shimmer-table-row {
    padding: 18px 0;
    margin-bottom: 0px;
    box-shadow: none;
    border-radius: 5px !important;
    min-height: 48px;
    & > .shimmer {
      margin: 0 20px;
      @media (max-width: map-get($mediaSizes, "mid")) {
        margin: 0 10px;
      }
    }
    &.shimmer-table-row--header {
      background: $shimmerTableHead;
      min-height: 57px;
      padding: 22px 0;
      .shimmer-table-col {
        background: rgba($black, 0.15) !important;
      }
    }
  }
}

.dynamicLink-table {
  width: 100%;
  .shimmer-table-row {
    padding: 18px 0;
    margin-bottom: 0px;
    box-shadow: none;
    border-radius: 5px !important;
    min-height: 48px;
    & > .shimmer {
      margin: 0 20px;
      @media (max-width: map-get($mediaSizes, "mid")) {
        margin: 0 10px;
      }
    }
    &.shimmer-table-row--header {
      background: $white;
      min-height: 48px;
      padding: 18px 0;
      .shimmer-table-col {
        background: rgba($black, 0.1) !important;
      }
    }
  }
}
@keyframes placeholderShimmer {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

.cu-shimmer {
  background-color: #f2f4f6 !important;
  background-image: linear-gradient(
    to right,
    $shimmerColor1 8%,
    $shimmerColor2 18%,
    $shimmerColor1 33%
  ) !important;
  background-repeat: no-repeat;
  background-size: 1000px 100%;
  position: relative;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
  &.br20 {
    border-radius: 20px;
  }
  &.br10{
    border-radius: 10px;
  }
  &.h20{
    height: 20px;
  }
  &.mh400 {
    min-height: 400px;
    @media (max-width: map-get($mediaSizes, "md")) {
      min-height: 300px;
    }
  }
}
.cu-shimmer-white {
  background-color: #f2f4f6 !important;
  background-image: linear-gradient(
    to right,
    $white 8%,
    $shimmerColor2 18%,
    $white 33%
  ) !important;
  background-repeat: no-repeat;
  background-size: 1000px 100%;
  position: relative;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
}
.shimmer-thumbnail {
  &.h150 {
    height: 150px !important;
  }
  &.h120 {
    height: 120px !important;
  }
  &.h150 {
    height: 150px !important;
  }
  &.h100 {
    height: 100px !important;
  }
  &.h40 {
    height: 40px !important;
  }
  &.h100p{
    height: 100px !important;
  }
  &.br20 {
    border-radius: 20px;
  }
  &.mb20 {
    margin-bottom: 20px;
  }
}
