@import "../../assets/scss/variable";

.estimation-left-data {
    width: calc(100% - 354px);
    padding-right: 40px;
    margin-top: 36px;

    @media (max-width: map-get($mediaSizes, "lg")) {
        width: 100%;
        padding-right: 0;
    }

    .input-side-label {
        background-color: $primary;
        color: $white;
        border: 1px solid $primary;
        border-radius: 0 50px 50px 0 !important;
        padding-inline: 40px
    }

    h2.heads-data {
        @include font2037();
        font-weight: 600;
        color: $primary;
        margin-bottom: 10px;
        text-align: start;

        @media (max-width: map-get($mediaSizes, "lg")) {
            @include font1626();
        }
    }

    p.header-text {
        @include font1620();
        color: $darkGreen;
        font-weight: 600;

    }

    // .payment-mode {
    //     span {
    //         font-weight: 600;
    //         color: $primary;
    //     }

    //     .payment-icon {
    //         border: 2px solid rgba(255, 255, 255, 0.1);
    //     }
    // }
    .fees-details {
        @include font1620;

        p {
            display: flex;
            justify-content: space-between;
            padding-bottom: 10px;
            border-bottom: 2px solid $lightGray;
            font-weight: 600;
            color: $fadedBlack;

            span {
                color: $primary;
                font-weight: 700;
            }
        }
    }

    ul.notes {
        color: #9B9B9D;
        font-weight: 500;
    }

}

