@import "./variable.scss";

// RTL CSS
[dir="rtl"] {
  body {
    counter-reset: myCounter;
  }

  .modal-body {
    .close-icon {
      right: unset;
      left: 0;

      &.above.diff {
        left: -15px;
        right: unset !important;
      }

      &.bottom-diff {
        left: 5px;
        right: unset !important;
      }
    }

    .back-icon {
      right: 40px;
      left: unset;
      transform: rotate(180deg);
    }
  }

  .telephone.cu-form-group input:focus,
  .telephone.cu-form-group input:not(:placeholder-shown),
  .telephone.cu-form-group input:hover {
    border-left: 1px solid $primary !important;
  }

  .telephone.cu-form-group input:focus~.flag-dropdown,
  .telephone.cu-form-group input:not(:placeholder-shown)~.flag-dropdown,
  .telephone.cu-form-group input:hover~.flag-dropdown {
    border-left: 0 !important;
    border-right: 1px solid $primary !important;
  }

  // Floating label
  .floating-label-group {

    input:focus~.floating-label,
    input:not(:placeholder-shown)~.floating-label,
    textarea:focus~.floating-label,
    textarea:not(:placeholder-shown)~.floating-label,
    .floating-label.show {
      right: 25px;
      left: unset !important;
    }

    .password-group {

      input.form-control:hover~.input-group-text,
      input.form-control:focus~.input-group-text,
      input.form-control:not(:placeholder-shown)~.input-group-text {
        border: 1px solid $primary !important;
        border-right: 1px solid $primary !important;
        border-right: 0 !important;
      }

      input.form-control {
        border-radius: 0 20px 20px 0 !important;
        border-right: 1px solid $cuColor87 !important;
        border-top: 1px solid $cuColor87 !important;
        border-bottom: 1px solid $cuColor87 !important;
        border-left: 0 !important;
        padding-right: 25px !important;
        padding-left: 0 !important;

        &:focus {
          border-right: 1px solid $primary !important;
          border-top: 1px solid $primary !important;
          border-bottom: 1px solid $primary !important;
          border-left: 0 !important;
        }
      }

      input.form-control:not(:placeholder-shown),
      input:hover {
        border-right: 1px solid $primary !important;
        border-top: 1px solid $primary !important;
        border-bottom: 1px solid $primary !important;
        border-left: 0 !important;
      }

      span.input-group-text,
      .input-group-text {
        border-radius: 20px 0 0 20px !important;
        border-right: 0 !important;
      }
    }
  }

  // cu-form-group
  .cu-form-group {
    span.required {
      left: unset;
      right: 15px;
    }

    &.telephone-active {
      .PhoneInput {
        .flag-dropdown {
          border-right: 1px solid $primary !important;
          border-left: 0 !important;
        }
      }
    }

    .PhoneInput {
      &:hover {
        .flag-dropdown {
          border: 1px solid $primary !important;
          border-left: 0 !important;
        }
      }

      .flag-dropdown {
        border-radius: 0 20px 20px 0 !important;
        border-right: 1px solid $cuColor87 !important;
        border-left: 0 !important;
        padding: 0 28px 0 0 !important;
      }

      input {
        border-right: 0 !important;
        border-left: 1px solid $cuColor87 !important;
        border-radius: 20px 0 0 20px !important;
        padding: 10px 0 10px 70px !important;
        margin-left: unset;
        margin-right: auto;

        @media (max-width: map-get($mediaSizes, "sm")) {
          padding: 10px 0 10px 35px !important;
        }
      }
    }
  }

  // select control
  .react-select {
    .select__control {
      &::before {
        right: unset;
        left: 15px;
      }
    }

    &.multi-select {
      .select__control {
        .select__value-container {
          padding: 8px 20px 5px 30px !important;

          .select__multi-value {
            .select__multi-value__label {
              padding-right: 6px !important;
              padding-left: 0 !important;
            }
          }
        }
      }
    }
  }

  // header
  header {
    .navbar {
      .navbar-brand {
        margin-right: unset;
        margin-left: 60px;

        @media (max-width: map-get($mediaSizes, "sm")) {
          margin-left: 10px;
          margin-right: unset;
        }
      }

      .mobile-list {
        span {
          padding-left: 12px;
        }

        li {
          display: inline-block;
          margin-left: 15px;
          margin-right: 0;

          @media (max-width: map-get($mediaSizes, "sm")) {
            margin-left: 5px;
            margin-right: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      ul {
        &.first {
          margin-left: auto !important;
          margin-right: 0 !important;
        }

        li {
          margin-left: 40px;
          margin-right: 0;

          &:last-child {
            margin-left: 0px;
          }
        }

        &.second {
          margin-left: 0 !important;
          margin-right: auto !important;

          span {
            padding-left: 12px;
          }

          li {
            margin-left: 20px;
            margin-right: 0;

            &:last-child {
              margin-left: 0;
            }
          }
        }
      }

      .icon-text {
        .badge-notification {
          left: -2px;
          right: unset;
        }
      }

      .common-dropdown {
        .dropdown-menu {
          left: 0 !important;
          right: unset !important;
          
          .dropdown-item {
            img {
              margin-right: 0px;
              margin-left: 5px;
            }
            
            .two {
              padding-left: 0;
              padding-right: 15px;
              text-align-last: right;
            }
          }
        }
      }

      .notification-dropdown {
        .dropdown-menu {
          left: -148px !important;
          right: unset !important;

          @media (max-width: map-get($mediaSizes, "lg")) {
            left: -138px !important;
            right: unset !important;
          }

          @media (max-width: map-get($mediaSizes, "sm")) {
            left: -125px !important;
          }

          .inner-notofication {
            ul {
              li {
                .not-body {
                  .badge {
                    left: -5px;
                    right: unset;
                  }

                  .not-data {
                    margin-left: 0;
                    margin-right: 15px;
                    text-align: right;
                    padding-left: 10px;
                    padding-right: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // Toastify
  .Toastify {
    .Toastify__toast-container {
      left: 15px;
      right: unset;
      margin-left: unset;
      margin-right: auto;

      @media (max-width: map-get($mediaSizes, "md")) {
        left: 10px;
        right: unset;
      }

      .Toastify__toast {
        margin: 10px auto 0px 4px !important;

        .Toastify__close-button {
          padding: 0 0 0 10px;
        }
      }
    }
  }

  // SLIDER SECTION
  .slider-section {
    .slick-track {
      margin-right: 0 !important;
      margin-left: unset !important;
    }

    .slick-arrow {
      &.slick-prev {
        right: 0px;
        left: unset;

        &::before {
          transform: rotate(-90deg);
        }
      }

      &.slick-next {
        left: 0px;
        right: unset;

        &::before {
          transform: rotate(90deg);
        }
      }
    }
  }

  .alert-section {
    .alert.alert-dismissible {
      direction: ltr;

      .btn-close {
        right: unset;
        left: 5px;
      }
    }
  }

  .floating-label-group {
    .autocomplete-dropdown-container {
      left: unset;
      right: 0;
    }
  }

  .add-branch-btn {
    .btn {
      .plus-icon {
        right: 10px;
        left: unset;
      }
    }
  }

  .comming-box {
    button {
      svg {
        margin-left: 8px !important;
        transform: rotate(180deg);
        margin-right: 0 !important;
      }
    }
  }

  .static-data {
    .inner-title-data {
      .arrowDiv {
        right: -10px;
        left: unset;

        svg {
          transform: rotate(180deg);
        }
      }
    }

    .right-design-data {
      .static-box {
        .inner-title-data {
          .arrowDiv {
            right: -10px;
            left: unset;
          }
        }

        .box-data {
          ul {
            li {
              padding-right: 20px;
              padding-left: 0px;

              &::before {
                left: unset;
                right: 0;
              }

              ol {
                counter-reset: myCounter;

                li {
                  &::before {
                    counter-increment: myCounter;
                    content: counter(myCounter);
                    left: unset;
                    right: 0;
                  }

                  &::after {
                    right: 10px;
                    left: unset;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .cu-check-pad {
    padding: 0 !important;
  }

  .dropdown-language {
    .nav-link {
      .country-flag-data {
        margin-right: 0;
        margin-left: 5px;
      }

      .selected-language {
        @include font1620();
        font-weight: 600;
        padding-left: 2px;
        padding-right: 0;
      }
    }

    .dropdown-menu.with-lang {
      right: unset !important;
      left: 0 !important;

      .dropdown-item {
        img {
          margin-right: 0px;
          margin-left: 5px;
        }
      }
    }
  }

 

  .react-tel-input .country-list {
    left: unset;
    right: 0;
  }
  .public-DraftStyleDefault-ltr {
    direction: rtl;
    text-align: right;
  }
  .rdw-dropdown-carettoopen {
    right: 60%;
    left: unset;
  }
  .rdw-dropdown-carettoclose {
    right: 60%;
    left: unset;
  }
  .public-DraftStyleDefault-unorderedListItem{
    direction: rtl;
    right: 1.5rem;
    left: 0;
    text-align: right;
  }
  .public-DraftStyleDefault-orderedListItem{
    direction: rtl;
    position: relative;
    right: 0;
    text-align: right;
  }

  .checkbox-square label {
    padding-right: 30px;

    &:before {
      left: unset !important;
      right: 2px;
    }

    &:after {
      transform: rotate(-320deg);
      right: 8px;
      left: unset !important;
      top: 2px;
    }
  }

  .progress-bar-line {
    height: 50px;
  }
  .under-progress {
    padding-top: 0px;
    padding-bottom: 1px ;
  }
  .on-way{
    padding-top: 0px;
    padding-bottom: 1px;
  }
  .waiting-proposal{
    padding-top: 19px;
    padding-bottom: 1px;
  }
}





