@import "../../assets/scss/variable";

// review
.payment-details-left-data {
    width: calc(100% - 354px);
    padding-right: 40px;
    margin-top: 36px;

    @media (max-width: map-get($mediaSizes, "lg")) {
        width: 100%;
        padding-right: 0;
    }

    .input-side-label {
        background-color: $primary;
        color: $white;
        border: 1px solid $primary;
        border-radius: 0 50px 50px 0 !important;
        padding-inline: 40px
    }

    h2.heads-data {
        @include font2037();
        font-weight: 600;
        color: $primary;
        margin-bottom: 10px;
        text-align: start;

        @media (max-width: map-get($mediaSizes, "lg")) {
            @include font1626();
        }
    }
}

.review-left-data {
    border: 1px solid $primary;
    padding: 20px;
    border-radius: 20px;

    h4 {
        @include font1830();
        font-weight: 600;
        color: $darkGreen;
        margin-bottom: 22px;

        @media (max-width: map-get($mediaSizes, "lg")) {
            @include font1626();
        }
    }

    textarea.form-control,
    input {
        &:disabled {
            background-color: $white !important;
            cursor: not-allowed;
        }
    }

    button:disabled {
        cursor: not-allowed !important;
    }

    .stars-data {
        span span {
            margin-right: 15px;
        }
    }

    .spare-parts {
        margin-top: 30px;

        .description {
            word-break: break-all;
        }
    }
}

.border-50 {
    border-radius: 50px 0 0 50px !important
}

// RTL CSS
[dir="rtl"] {
    .payment-details-left-data {
        padding-left: 40px;
        padding-right: 0;

        @media (max-width: map-get($mediaSizes, "lg")) {
            padding-left: 0px;

        }
    }

    .review-left-data {
        .input-side-label {
            background-color: $primary;
            color: $white;
            border: 1px solid $primary;
            border-radius: 50px 0 0 50px !important;
            padding-inline: 40px
        }

        .border-50 {
            border-radius: 0 50px 50px 0 !important
        }
    }
}