@import "../../assets/scss/variable";

// RFP SECTION

ul.all-question-type {
  padding: 0 5px;
  margin: 10px 0 0 0;

  li {
    display: inline-block;
    padding-right: 30px;
    margin-bottom: 10px;

    .form-checkbox {
      display: flex;
      align-items: center;

      &.with-radio {
        input {
          &:checked+label {
            &:after {
              background-color: $white;
            }
          }
        }

        label {
          position: relative;
          cursor: pointer;

          &:before {
            content: "";
            position: absolute;
            width: 24px;
            height: 24px;
            left: 0;
            top: 0px;
            border-radius: 50%;
            border: 1px solid $lightGreen;
          }

          &:after {
            content: "";
            position: absolute;
            width: 10px;
            height: 10px;
            left: 7px;
            top: 7px;
            border-radius: 50%;
            background-color: transparent;
            border: 0;
          }
        }
      }

      input {
        &:checked+Label {
          &:before {
            background-color: $lightGreen;
          }

          &:after {
            border-width: 0 2px 2px 0 !important;
          }
        }
      }

      label {
        color: $darkGreen;
        margin-bottom: 0;
        padding-bottom: 0;
        padding-left: 35px;
        padding-top: 0;
        position: relative;
        cursor: pointer;
        font-size: 16px;
        line-height: 24px;

        &:before {
          content: "";
          position: absolute;
          width: 25px;
          height: 25px;
          left: 0;
          top: 0;
          border-radius: 8px;
          border: 1px solid $lightGreen;
        }

        &:after {
          content: "";
          position: absolute;
          left: 10px;
          top: 5.5px;
          width: 5px;
          height: 12px;
          border: solid $white;
          border-width: 0 0 0 0 !important;
          transform: rotate(45deg);
        }
      }
    }
  }
}

.list-text-color {
  color: $darkGreen !important;
}

.guarantee-check.full-check {
  input {
    &:checked+label {
      &:before {
        background-color: $primary;
      }
    }
  }

  label {
    position: relative;
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
    color: $darkGreen;
    margin-bottom: 0;
    padding-left: 40px;
    padding-top: 2px;
    cursor: pointer;

    a {
      color: $lightGreen;
      font-weight: 600;
      text-decoration: underline;
    }

    &:before {
      content: "";
      position: absolute;
      min-width: 30px;
      min-height: 30px;
      left: 0;
      top: 0;
      border-radius: 10px;
      background-color: $white;
      border: 1px solid $primary;
    }

    &:after {
      content: "";
      position: absolute;
      left: 12px;
      top: 6px;
      width: 6px;
      height: 14px;
      border: solid $white;
      border-width: 0 2px 2px 0 !important;
      transform: rotate(45deg);
    }
  }
}

.filter-tab {
  margin-bottom: 25px;

  ul {
    padding: 0;
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;
    width: fit-content;
    vertical-align: middle;

    li {
      margin-right: 15px;
      display: inline-block;

      &:last-child {
        margin-right: 0;
      }

      &.input-li {
        min-width: 300px;

        @media (max-width: map-get($mediaSizes, "sm")) {
          min-width: 240px;
        }
      }
    }
  }
}

.branch-header {
  &.rfp-close-header {
    position: absolute;
    right: -10px;
    top: -10px;
  }

  .close-branch {
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    background-color: $danger;
    color: $white;
    cursor: pointer;

    @include all_center();

    svg {
      @include trans3();
    }

    &:hover {
      svg {
        transform: rotate(-90deg);
      }
    }
  }

  .two {
    width: calc(100% - 30px);
    color: $cuColor87;
    @include font1420();
    font-weight: 600;
  }
}

.filter-body {
  Label {
    @include font1626();
    font-weight: 600;
    color: $cuColor87;
  }

  .filter-btn {
    @include all_center();
  }

  .rating-filter {
    .user-select-none {
      display: flex;
      align-items: center;
      margin-right: 2.5px;

      span {
        margin-right: 2.5px;
      }
    }

    .rating-head {
      margin-right: 15px;
    }
  }
}

.question-box {
  border-bottom: 1px solid $lightGreen;
  margin-bottom: 20px;

  &:last-child {
    border-bottom: 0;
  }

  .question-inner-box {
    p.label-text {
      &.font-700 {
        font-weight: 700;
        @include font1830();
        margin-bottom: 0
      }

      @include font1626();
      margin-bottom: 8px;
      font-weight: 600;
      color: $primary;
      position: relative;
      z-index: 1;

      &::first-letter {
        text-transform: uppercase;
      }

      span.required {
        color: $danger;
        @include font2020();
        font-weight: 700;
        position: absolute;
        top: 3px;
        z-index: 9;
      }
    }

    p.answer-text {
      color: $cuColor87;
      font-weight: 600;
      word-break: break-all;
      @include font1830()
    }

    textarea.form-control,
    input {
      &:disabled {
        background-color: $white !important;
        cursor: not-allowed;
      }
    }

    label.disabled {
      cursor: not-allowed !important;
    }
  }
}

// RTL CSS
[dir="rtl"] {
  .filter-tab {
    ul {
      margin: 0 auto 0 0;

      li {
        margin-left: 15px;
        margin-right: unset;
        display: inline-block;

        &:last-child {
          margin-left: 0;
        }
      }
    }
  }

  .branch-header {
    &.rfp-close-header {
      right: unset;
      left: -10px;
    }
  }

  .filter-body {
    .rating-filter {
      .user-select-none {
        margin-right: 0;
        margin-left: 2.5px;

        span {
          margin-right: 0;
          margin-left: 2.5px;
        }
      }

      .rating-head {
        margin-right: 0;
        margin-left: 15px;
      }
    }
  }

  ul.all-question-type {
    li {
      padding-right: 0;
      padding-left: 30px;

      .form-checkbox {
        &.with-radio {

          label {
            &:before {
              left: unset;
              right: 0;
            }

            &:after {
              left: unset;
              right: 7px;
            }
          }
        }

        label {
          padding-left: unset;
          padding-right: 35px;

          &:before {
            left: unset;
            right: 0;
          }

          &:after {
            left: unset;
            right: 10px;
          }
        }
      }
    }
  }
}

.text-center {
  text-align: center;

  .modal-text {
    margin-left: 5px;
    text-decoration: none;
    color: $cuColor87;
  }
}